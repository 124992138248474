import { CSSProperties, ReactElement, useEffect } from "react";
import styled from "styled-components";
import responsiveStyling from "lib/responsive";
import Text, { TextType } from "./Text";
import { PrimaryColorPalette } from "lib/styles/colors";
import BrandLogoInverted from "lib/svgs/BrandLogoInverted";
import Image from "next/image";
import {
  AnalyticsEvents,
  InternalAnalyticsEvents,
} from "lib/analytics/constants";
import { useTracking } from "core/tracking/useTracking";
import { track } from "lib/analytics/analytics";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 90px;
  height: 100vh;
  background: linear-gradient(135deg, #2c7eab 0%, #3caceb 47.92%, #24d9ff 100%);
`;

const TextContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 270px;
  padding: 16px;
  ${responsiveStyling({
    ">480": `
      width: 340px;
    `,
  })}
`;

const TextStyle: CSSProperties = {
  paddingTop: "40px",
  textAlign: "center",
};

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  ${responsiveStyling({
    ">480": `    
      flex-direction: row;
    `,
  })}
`;

const APP_STORE_ID = "1530699354";
const PLAY_STORE_ID = "com.tenure.tenuremobile";
// US version
// export const APP_STORE_LINK = `https://itunes.apple.com/us/app/${APP_NAME}/id${APP_STORE_ID}?mt=8`;
// generic link
export const APP_STORE_LINK = `http://itunes.apple.com/app/id${APP_STORE_ID}`;
export const PLAY_STORE_LINK = `https://play.google.com/store/apps/details?id=${PLAY_STORE_ID}`;

export default function AppStoreRedirect(): ReactElement {
  const { internalTrackEvent } = useTracking();

  useEffect(() => {
    track(AnalyticsEvents.SMALL_SCREEN_REDIRECT);
  }, []);

  useEffect(() => {
    internalTrackEvent(InternalAnalyticsEvents.SMALL_SCREEN_REDIRECT);
  }, []);

  return (
    <Container>
      <BrandLogoInverted />
      <TextContainerWrapper>
        <Text
          fontColor={PrimaryColorPalette.MAIN_6}
          type={TextType.TITLE_LARGE}
          style={TextStyle}
        >
          10ure web application is currently only available on desktop/laptop
          devices. To experience 10ure on mobile device, please download our
          mobile app below:
        </Text>
      </TextContainerWrapper>

      <TextContainerWrapper>
        <Text
          fontColor={PrimaryColorPalette.MAIN_6}
          type={TextType.LABEL_2}
          style={TextStyle}
        >
          Download 10ure App
        </Text>
      </TextContainerWrapper>
      <ButtonsRow>
        <a href={PLAY_STORE_LINK}>
          {/* <img
            src='/images/google-playimg.png'
            alt='Get it on Google Play'
            width='126'
            height='38'
          /> */}
          <Image
            src="/images/google-playimg.png"
            alt="Get it on Google Play"
            width={126}
            height={38}
          />
        </a>
        <a href={APP_STORE_LINK}>
          {/* <img
            src="/images/appstoreimg.png"
            alt="Download on the App Store"
            width="126"
            height="38"
          /> */}
          <Image
            src="/images/appstoreimg.png"
            alt="Download on the App Store"
            width={126}
            height={38}
          />
        </a>
      </ButtonsRow>
    </Container>
  );
}
