import { getAuthClient } from "lib/http/api";
import { useCallback, useState } from "react";
import { AxiosResponse } from "axios";

export function useEmailSubscription(): {
  subscribe: (email: string) => Promise<boolean>;
  isPending: boolean;
} {
  const [isPending, setPending] = useState(false);

  const subscribe = useCallback(
    async (email: string): Promise<boolean> => {
      setPending(true);
      const body = { email: email };

      return getAuthClient(undefined, true)
        .post("/v1/weekly/digest/subscribe", body)
        .then((res: AxiosResponse<any>) => {
          if (res.status === 200) {
            return true;
          }
          return false;
        })
        .catch((e: any) => {
          console.warn("error submitting: ", e);
          return false;
        })
        .finally(() => setPending(false));
    },
    [setPending]
  );

  return {
    isPending,
    subscribe,
  };
}
