import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { AppValues } from "lib/context/AppContext";
import { useToast } from "@chakra-ui/react";
import { getCookie } from "lib/vanillaCookie";

export function useAuthByQueryParams(
  setAppValues: (values: Partial<AppValues>) => void
) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const router = useRouter();
  const toast = useToast();
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (!router.isReady) return;
    if (router.query["userrefid"] && router.query["token"]) {
      const user_ref_id = router.query["userrefid"] as string;
      const token = getCookie(document.cookie, "userToken");
      setAppValues({
        token,
        userRefId: user_ref_id,
      });
      localStorage.setItem("userRefId", user_ref_id);
      timeout = setTimeout(() => {
        setIsLoading(false);
      }, 200);
    } else {
      setIsLoading(false);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [router]);

  useEffect(() => {
    if (!router.isReady) return;
    if (router.query["exchangeerror"]) {
      toast({
        description: `Error signing in. Please sign in.`,
        duration: 3000,
        isClosable: true,
        status: "warning",
        title: "Warning",
      });
    }
  }, []);
  return { isLoading };
}
