import { destroyCookie } from "nookies";

export function clearDataForLogout() {
  destroyCookie(null, "xToken");
  destroyCookie(null, "userToken");
  // dashboard token
  destroyCookie(null, "token");
  destroyCookie(null, "userRefId");
  const storage = process.browser ? localStorage : undefined;
  storage && storage.clear();
}
