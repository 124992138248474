import { Box } from "@chakra-ui/react";
import { ButtonType } from "components/Button";
import ButtonLink from "components/ButtonLink";
import { TextType } from "components/Text_DEPRECATED";
import { TextAndShapesPalette } from "lib/styles/colors";

interface OwnProps {
  title: string;
  attrTitle?: string;
  focused: boolean;
  link: string;
  newTab?: boolean;
  small?: boolean;
  handleTrack: () => void;
}

export default function NavbarButton(props: OwnProps): JSX.Element {
  const { title, attrTitle, focused, link, newTab, handleTrack, small } = props;
  const { textType, styleType } = getTextFormatting(focused, small);
  return (
    <Box
      paddingBottom="10px"
      marginBottom="24px"
      paddingX="0.25rem"
      borderBottomWidth={focused ? "4px" : undefined}
      borderBottomColor={focused ? "brand.Branding" : undefined}
    >
      <ButtonLink
        marginTop="54px"
        background="primary.7"
        attrTitle={attrTitle}
        textColor={focused ? "brand.Branding" : TextAndShapesPalette.INPUT}
        styleType={styleType}
        textType={textType}
        href={link}
        fontSize="16px"
        newTab={newTab ?? false}
        onClick={handleTrack}
      >
        {title}
      </ButtonLink>
    </Box>
  );
}

function getTextFormatting(
  focused: boolean,
  small?: boolean
): {
    textTransform: boolean;
    textType: TextType;
    styleType: ButtonType;
  } {
  if (small) {
    return {
      styleType: ButtonType.NAV_INACTIVE,
      textTransform: true,
      textType: TextType.NAV_INACTIVE,
    };
  }
  return {
    styleType: ButtonType.NAV_INACTIVE,
    textTransform: true,
    textType: focused ? TextType.NAV_ACTIVE : TextType.NAV_INACTIVE,
  };
}
