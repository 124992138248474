import { Flex } from "@chakra-ui/react";
import { AppRoutes, ScreenSizes } from "core/home/types";
import { useAppValues } from "lib/context/AppContext";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

interface OwnProps {
  children: React.ReactNode;
}
/**
 * A default container for pages to give a consistent width
 * and padding
 * @deprecated - moving away from chakra
 * @param props - and page children
 */
export default function PageContainer(props: OwnProps): JSX.Element {
  const router = useRouter();
  const [noMax, setNoMax] = useState<boolean>(false);
  const { screenSize } = useAppValues().getAppValues();
  const isPhone =
    screenSize === ScreenSizes.XS || screenSize === ScreenSizes.SM;

  useEffect(() => {
    if (!router.isReady) return;
    const setNoMaxTrue =
      router.pathname === AppRoutes.JOBS_HOME ||
      router.pathname === AppRoutes.NEWS_HOME ||
      router.pathname.includes("v2");

    if (setNoMaxTrue) {
      setNoMax(true);
    }
  }, [router.isReady]);

  return (
    <Flex
      maxWidth={noMax || isPhone ? undefined : "1920px"}
      height="100%"
      minH="100vh"
      width="100%"
      flexDirection="column"
      paddingX={noMax || isPhone ? undefined : "80px"}
    >
      {props.children}
    </Flex>
  );
}
