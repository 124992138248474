import ButtonDEPRECATED, { ButtonType } from "components/Button";
import Modal from "components/Modal";
import Text, { TextType } from "components/Text";
import {
  BrandColorPalette,
  PrimaryColorPalette,
  TextAndShapesPalette,
} from "lib/styles/colors";
import React, { ReactElement } from "react";
import styled from "styled-components";
import * as gtag from "lib/analytics/gtag";
import {
  AnalyticsEvents,
  InternalAnalyticsEvents,
} from "lib/analytics/constants";
import { track } from "lib/analytics/analytics";
import { useTracking } from "core/tracking/useTracking";
import { TextType as ButtonTextType } from "components/Text_DEPRECATED";
import ButtonLink from "components/ButtonLink";
import SendAppToPhoneButton from "../SendAppToPhoneButton";
import { POST_JOB_REDIRECT } from "../Navbar";
import router from "next/router";
import CloseBtnIcon from "lib/svgs/CloseBtnIcon";

const ContentContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 24px 24px 40px 24px;
  background-color: ${PrimaryColorPalette.MAIN_6};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20px;
`;

const CloseButtonWrapper = styled.div`
  width: 20px;
  height: 20px;
`;

const NavButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;
  align-items: center;
`;

const Spacer = styled.div`
  background-color: ${TextAndShapesPalette.PLACEHOLDER};
  height: 1px;
  width: 100%;
  margin-bottom: 32px;
`;
const SendAppToPhoneContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 32px;
  justify-content: center;
`;

interface Props {
  modalEnabled: boolean;
  userRefId?: string;
  path?: string;
  currentPathname: string;
  showSendAppToPhoneButton: boolean;
  handleClose: () => void;
  handleSignIn: () => void;
}

const JOBS_BASE_ROUTE = "/jobs";
const NEWS_BASE_ROUTE = "/news";

export default function MobileNavPopUpModal({
  modalEnabled,
  currentPathname,
  userRefId,
  path,
  showSendAppToPhoneButton,
  handleClose,
  handleSignIn,
}: Props): ReactElement {
  const { internalTrackEvent } = useTracking();
  const { isJobsRoute, isNewsRoute } = compareCurrentPath(currentPathname);

  const handleTrackJobsClick = () => {
    gtag.event({
      action: "navbar_click_jobs_tab",
      category: "engagement",
    });
    internalTrackEvent(InternalAnalyticsEvents.NAVBAR_CLICK_JOBS_TAB);
    track(AnalyticsEvents.NAVBAR_CLICK_JOBS_TAB);
  };

  const handleTrackNewsClick = () => {
    gtag.event({
      action: "navbar_click_news_tab",
      category: "engagement",
    });
    internalTrackEvent(InternalAnalyticsEvents.NAVBAR_CLICK_NEWS_TAB);
    track(AnalyticsEvents.NAVBAR_CLICK_NEWS_TAB);
  };

  const handleTrackAboutUsClick = () => {
    gtag.event({
      action: "navbar_click_about_us_tab",
      category: "engagement",
    });
    internalTrackEvent(InternalAnalyticsEvents.NAVBAR_CLICK_ABOUT_US_TAB);
    track(AnalyticsEvents.NAVBAR_CLICK_ABOUT_US_TAB);
  };

  const handleTrackPostJobClick = () => {
    gtag.event({
      action: "navbar_click_post_new_job",
      category: "engagement",
    });
    internalTrackEvent(InternalAnalyticsEvents.NAVBAR_CLICK_POST_NEW_JOB);
    track(AnalyticsEvents.NAVBAR_CLICK_POST_NEW_JOB);
  };

  const handleNav = (route: string) => {
    if (route === JOBS_BASE_ROUTE) {
      handleTrackJobsClick();
      router.push(route);
      handleClose();
      return;
    }
    if (route === NEWS_BASE_ROUTE) {
      handleTrackNewsClick();
      router.push(route);
      handleClose();
      return;
    }
    handleTrackAboutUsClick();
    router.push("https://product.10ure.com");
  };
  return (
    <Modal
      closeOnEsc
      transparent
      closeOnOverlayClick
      isOpen={modalEnabled}
      onClose={handleClose}
      fullWidth
    >
      <ContentContainer>
        <Header>
          <CloseButtonWrapper onClick={handleClose}>
            <CloseBtnIcon />
          </CloseButtonWrapper>
        </Header>
        <NavTextButton
          handleNav={handleNav}
          title="Job Board"
          route={JOBS_BASE_ROUTE}
          isSelected={isJobsRoute}
        />
        <NavTextButton
          handleNav={handleNav}
          title="Maritime News"
          route={NEWS_BASE_ROUTE}
          isSelected={isNewsRoute}
        />
        <NavTextButton
          handleNav={handleNav}
          title="Contact Us"
          route={"/contactus"}
          isSelected={false}
        />
        <Spacer />
        {showSendAppToPhoneButton && (
          <SendAppToPhoneContainer>
            <SendAppToPhoneButton userRefId={userRefId} path={path} />
          </SendAppToPhoneContainer>
        )}
        <ButtonLink
          textDecoration="none"
          marginRight="2rem"
          marginBottom="32px"
          width="100%"
          styleType={ButtonType.CANCEL_SECONDARY_BLACK}
          textType={ButtonTextType.BUTTON}
          href={POST_JOB_REDIRECT}
          onClick={handleTrackPostJobClick}
        >
          Post Jobs for Free
        </ButtonLink>
        {!userRefId && (
          <ButtonDEPRECATED
            textDecoration="none"
            width="100%"
            styleType={ButtonType.PRIMARY_BLACK}
            textType={ButtonTextType.BUTTON}
            onClick={handleSignIn}
          >
            Job Seeker Sign In
          </ButtonDEPRECATED>
        )}
      </ContentContainer>
    </Modal>
  );
}

interface NavButtonProps {
  title: string;
  route: string;
  isSelected: boolean;
  handleNav: (route: string) => void;
}
function NavTextButton({
  title,
  route,
  isSelected,
  handleNav,
}: NavButtonProps): ReactElement {
  const handleClick = () => {
    handleNav(route);
  };

  return (
    <NavButtonContainer onClick={handleClick}>
      <Text
        type={TextType.HEADING_MEDIUM}
        fontColor={
          isSelected ? BrandColorPalette.BRANDING : TextAndShapesPalette.INPUT
        }
        style={{ textAlign: "center" }}
      >
        {title}
      </Text>
    </NavButtonContainer>
  );
}

function compareCurrentPath(currentPathname: string): {
  isJobsRoute: boolean;
  isNewsRoute: boolean;
} {
  if (!currentPathname || currentPathname === "/") {
    return {
      isJobsRoute: false,
      isNewsRoute: false,
    };
  }
  if (currentPathname.includes(JOBS_BASE_ROUTE)) {
    return {
      isJobsRoute: true,
      isNewsRoute: false,
    };
  }
  if (currentPathname.includes(NEWS_BASE_ROUTE)) {
    return {
      isJobsRoute: false,
      isNewsRoute: true,
    };
  }
  return {
    isJobsRoute: false,
    isNewsRoute: false,
  };
}
