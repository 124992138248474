import React from "react";

const BASE_WIDTH = 144;
const BASE_HEIGHT = 79;

export default function SeagullMobileIcon(props: {
  height?: number;
  width?: number;
}) {
  const width = props.width || BASE_WIDTH;
  const height = props.height || BASE_HEIGHT;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${BASE_WIDTH} ${BASE_HEIGHT}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.8658 130.164C69.0909 131.055 79.9475 124.531 90.7525 118.972C101.4 113.495 112.414 108.195 118.856 98.2751C125.725 87.6995 134.898 82.5116 130.61 70.6314C126.406 58.9824 117.27 46.6888 106.508 40.1695C98.404 35.2597 87.3498 29.4626 78.055 27.4492C68.1765 25.3092 52.2595 24.6884 43.0058 28.5763C33.4728 32.5816 28.1525 41.906 22.4448 50.3589C15.9389 59.9939 7.55357 69.3342 7.66457 80.9152C7.78335 93.3076 14.1579 105.133 23.0552 114.039C32.0399 123.032 44.0752 129.231 56.8658 130.164Z"
          fill="#DFE2E3"
        />
        <path
          d="M0.36438 79H143.694C138.971 43.472 108.686 16.0678 72.0294 16.0678C35.3728 16.0678 5.08735 43.472 0.36438 79Z"
          fill="url(#paint90_linear)"
        />
        <g filter="url(#filter90_d)">
          <rect
            x="54.5931"
            y="18.1818"
            width="36.9676"
            height="81.4442"
            rx="18.4838"
            fill="#E5F7FF"
          />
          <circle cx="65.2263" cy="38.7518" r="2.59928" fill="#3CACEB" />
          <circle cx="81.2924" cy="38.7502" r="2.59763" fill="#3CACEB" />
          <ellipse
            cx="73.0373"
            cy="53.3127"
            rx="12.4188"
            ry="5.77619"
            fill="#06C5EF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M85.4562 53.3101L60.6186 53.3101C60.6186 50.12 66.1787 47.5339 73.0374 47.5339C79.8961 47.5339 85.4562 50.12 85.4562 53.3101Z"
            fill="#47DDFE"
          />
          <path
            d="M53.2542 63.2669L71.1213 78.2113L54.8571 90.0442C52.3926 87.914 45.8862 80.6675 39.5764 68.7239C31.6891 53.7945 36.9253 41.2495 38.1906 41.5085C39.2029 41.7156 39.5546 42.2523 41.8728 45.8602C44.1736 49.4408 44.2161 50.2119 45.9018 53.2751C47.2976 55.8115 51.4157 60.8692 53.2542 63.2669Z"
            fill="#64BDEF"
          />
          <path
            d="M52.9194 62.2627C55.3132 64.6027 74.4345 79.4179 74.4345 79.4179L58.1104 87.6796C55.6459 85.5493 49.867 79.9019 43.5571 67.9583C35.6698 53.0289 37.0746 41.8068 38.1906 41.5085C39.1888 41.2416 42.2075 45.8602 42.2075 45.8602C42.2075 45.8602 45.555 52.2204 47.5635 55.5678C49.5719 58.9153 50.5256 59.9228 52.9194 62.2627Z"
            fill="#E5F7FF"
          />
          <path
            d="M80.0093 24.2632C83.311 22.5673 82.5134 20.3767 81.676 19.4934H65.4232C64.5858 20.3767 63.7882 22.5673 67.0898 24.2632C70.3915 25.9592 73.5496 26.5598 73.5496 26.5598C73.5496 26.5598 76.7077 25.9592 80.0093 24.2632Z"
            fill="#3CACEB"
          />
          <path
            d="M69.5784 7.2617C72.109 6.50599 74.8046 6.50599 77.3351 7.2617C82.6019 8.83457 90.4261 14.4927 81.7987 18.1599L81.288 18.5851L65.4613 18.5851L65.1149 18.1599C56.4875 14.4927 64.3116 8.83448 69.5784 7.2617V7.2617Z"
            fill="#E5F7FF"
          />
          <path
            d="M65.792 16.9495C66.8895 17.3079 69.3631 17.9197 73.4607 17.9197C77.5584 17.9197 80.032 17.308 81.1294 16.9495C81.4617 16.8411 81.8026 17.0879 81.8026 17.4373L81.8026 19.6767C80.7653 20.1811 79.4323 20.529 78.1375 20.768C75.0473 21.3386 71.8742 21.3386 68.784 20.768C67.4893 20.5289 66.1562 20.1811 65.1188 19.6767L65.1188 17.4373C65.1188 17.0879 65.4598 16.8411 65.792 16.9495V16.9495Z"
            fill="#6BC3F4"
          />
          <path
            d="M73.723 15.94C73.723 16.0144 73.6972 16.0806 73.6455 16.1385C73.5938 16.1964 73.5292 16.2253 73.4517 16.2253C73.2708 16.2253 73.1804 16.1385 73.1804 15.9648L73.1804 15.94C72.6119 15.8739 72.1231 15.6485 71.714 15.2639C71.3049 14.8794 71.0658 14.4142 70.9969 13.8683C70.9711 13.7112 71.0529 13.6161 71.2424 13.583C71.3113 13.5665 71.3781 13.581 71.4427 13.6264C71.5073 13.6719 71.5439 13.736 71.5525 13.8187C71.6042 14.2322 71.7829 14.5858 72.0887 14.8794C72.3944 15.173 72.7583 15.3487 73.1804 15.4066L73.1804 13.0496L72.3535 13.0496C72.2674 13.0496 72.1985 13.0248 72.1468 12.9752C72.0951 12.9256 72.0693 12.8635 72.0693 12.7891C72.0693 12.6072 72.164 12.5162 72.3535 12.5162L73.1804 12.5162L73.1804 11.9704C72.9392 11.9125 72.7411 11.7863 72.5861 11.592C72.431 11.3976 72.3535 11.1764 72.3535 10.9283C72.3535 10.6389 72.4612 10.3908 72.6765 10.184C72.8918 9.97725 73.1502 9.87387 73.4517 9.87387C73.7532 9.87387 74.0116 9.97725 74.2269 10.184C74.4422 10.3908 74.5499 10.6389 74.5499 10.9283C74.5499 11.1764 74.4724 11.3956 74.3173 11.5858C74.1623 11.776 73.9642 11.9042 73.723 11.9704L73.723 12.5162L74.5499 12.5162C74.7394 12.5162 74.8341 12.6072 74.8341 12.7891C74.8341 12.8635 74.8083 12.9256 74.7566 12.9752C74.7049 13.0248 74.636 13.0496 74.5499 13.0496L73.723 13.0496L73.723 15.4066C74.1537 15.3487 74.5241 15.1709 74.8341 14.8732C75.1442 14.5754 75.3165 14.224 75.3509 13.8187C75.3768 13.6368 75.4887 13.5582 75.6868 13.583C75.8505 13.6161 75.9323 13.7112 75.9323 13.8683C75.8462 14.4224 75.5986 14.8918 75.1894 15.2763C74.7803 15.6609 74.2915 15.8821 73.723 15.94ZM74.0073 10.9283C74.0073 10.7877 73.9513 10.6657 73.8393 10.5624C73.7273 10.459 73.5981 10.4073 73.4517 10.4073C73.3053 10.4073 73.1761 10.459 73.0641 10.5624C72.9521 10.6657 72.8962 10.7877 72.8962 10.9283C72.8962 11.0772 72.9521 11.2033 73.0641 11.3067C73.1761 11.41 73.3053 11.4617 73.4517 11.4617C73.5981 11.4617 73.7273 11.41 73.8393 11.3067C73.9513 11.2033 74.0073 11.0772 74.0073 10.9283Z"
            fill="#F5DC1D"
          />
        </g>
        <path
          d="M51.9165 38.6024L37.5728 34.759C36.9642 34.5962 36.307 34.7146 35.7457 35.0882C35.1843 35.4617 34.7648 36.0599 34.5791 36.7512L27.1731 64.3909C26.9885 65.0824 27.0529 65.8101 27.3522 66.4142C27.6515 67.0183 28.1613 67.4494 28.7696 67.613L43.1133 71.4564C43.722 71.6188 44.3791 71.5003 44.9406 71.1269C45.502 70.7535 45.9219 70.1557 46.1083 69.4646L53.5132 41.829C53.6978 41.1372 53.6332 40.4094 53.3336 39.8052C53.034 39.201 52.524 38.7699 51.9154 38.6065"
          fill="#405866"
        />
        <g clipPath="url(#clip1)">
          <rect
            x="35.4457"
            y="36.3898"
            width="18.0685"
            height="25.5085"
            rx="2.12571"
            transform="rotate(15 35.4457 36.3898)"
            fill="white"
          />
          <rect
            x="35.7208"
            y="35.3631"
            width="18.0685"
            height="31.8856"
            transform="rotate(15 35.7208 35.3631)"
            fill="url(#paint91_linear)"
          />
          <path
            d="M37.9479 52.6651L37.446 54.5384C37.4376 54.5698 37.4316 54.5882 37.4281 54.5936C37.425 54.597 37.4147 54.5963 37.397 54.5916L37.3116 54.5687C37.2979 54.565 37.2898 54.5597 37.2875 54.5528C37.2876 54.5444 37.2893 54.5343 37.2924 54.5225L37.788 52.6728L37.1741 52.9376C37.1433 52.9504 37.1222 52.9427 37.1109 52.9144L37.0931 52.8749C37.0878 52.863 37.0868 52.8511 37.09 52.8393C37.0931 52.8275 37.1054 52.8171 37.1267 52.8081L37.7438 52.5315C37.7985 52.5083 37.8484 52.5027 37.8935 52.5148L37.8994 52.5164C37.9539 52.5331 37.97 52.5827 37.9479 52.6651Z"
            fill="white"
          />
          <path
            d="M39.024 52.8177L39.0593 52.8272C39.2204 52.8703 39.3438 52.9518 39.4295 53.0716C39.5153 53.1914 39.5324 53.3475 39.4808 53.5399L39.2369 54.4501C39.1843 54.6464 39.0916 54.7805 38.9587 54.8522C38.8264 54.922 38.6723 54.9354 38.4965 54.8925L38.4788 54.8878C38.3031 54.8365 38.1754 54.7476 38.0957 54.621C38.0165 54.4924 38.0032 54.3299 38.0558 54.1336L38.2997 53.2235C38.3344 53.0939 38.3918 52.9935 38.4719 52.9223C38.6161 52.7926 38.8002 52.7577 39.024 52.8177ZM39.0274 52.9702L38.9802 52.9575C38.8644 52.9265 38.7565 52.9323 38.6567 52.975C38.5574 53.0158 38.4867 53.1147 38.4446 53.2718L38.2039 54.1701C38.1218 54.4764 38.2231 54.6677 38.5079 54.744L38.5432 54.7535C38.8279 54.8298 39.0113 54.7148 39.0934 54.4084L39.3341 53.5101C39.3762 53.353 39.3644 53.232 39.2988 53.1471C39.2337 53.0602 39.1432 53.0012 39.0274 52.9702Z"
            fill="white"
          />
          <path
            d="M39.9501 53.6909L39.9736 53.6972C40.0129 53.7078 40.0273 53.7327 40.0167 53.7719L39.7674 54.7027C39.7363 54.8185 39.7414 54.9135 39.7825 54.9877C39.8257 55.0624 39.9002 55.114 40.0063 55.1424C40.1123 55.1708 40.2149 55.153 40.3141 55.0891C40.3376 55.0722 40.3591 55.0664 40.3788 55.0717C40.4161 55.0817 40.4289 55.1083 40.4174 55.1515C40.4126 55.1692 40.4035 55.1836 40.39 55.1947C40.336 55.2391 40.2689 55.2696 40.1888 55.286C40.1091 55.3004 40.03 55.2971 39.9515 55.2761C39.816 55.2398 39.7174 55.1681 39.6556 55.061C39.5962 54.9525 39.5871 54.8217 39.6281 54.6685L39.8783 53.7348C39.8888 53.6956 39.9128 53.6809 39.9501 53.6909ZM40.8203 53.9304L40.8498 53.9383C40.8871 53.9483 40.9005 53.9729 40.89 54.0122L40.6035 55.0814C40.5829 55.158 40.5746 55.2126 40.5785 55.2452C40.5849 55.2764 40.6054 55.3019 40.6401 55.3217L40.6701 55.3392C40.698 55.353 40.7093 55.3697 40.7041 55.3893L40.6954 55.4217C40.6864 55.4551 40.6623 55.4665 40.6231 55.456C40.5268 55.4302 40.4677 55.3839 40.4457 55.3169C40.4256 55.2505 40.4313 55.1584 40.4629 55.0406L40.7486 53.9743C40.7591 53.9351 40.783 53.9204 40.8203 53.9304Z"
            fill="white"
          />
          <path
            d="M41.8588 54.2024C41.8961 54.2124 41.9095 54.237 41.899 54.2763L41.8935 54.2969C41.8893 54.3126 41.8797 54.3248 41.8648 54.3334C41.8518 54.3425 41.8374 54.345 41.8217 54.3408C41.7098 54.3108 41.6128 54.3154 41.5308 54.3544C41.4488 54.3935 41.392 54.4719 41.3604 54.5897L41.1102 55.5234C41.1055 55.5411 41.0957 55.5542 41.0807 55.5629C41.0663 55.5695 41.0513 55.5707 41.0355 55.5665L41.0149 55.561C40.9992 55.5568 40.9868 55.5482 40.9776 55.5352C40.969 55.5203 40.9671 55.504 40.9718 55.4863L41.222 54.5526C41.2651 54.3916 41.3427 54.2829 41.4546 54.2266C41.567 54.1684 41.7017 54.1603 41.8588 54.2024Z"
            fill="white"
          />
          <path
            d="M42.085 54.7839C42.1266 54.6288 42.2058 54.5216 42.3227 54.4624C42.4396 54.4032 42.5736 54.3939 42.7248 54.4344C42.876 54.4749 42.9862 54.5508 43.0553 54.6619C43.125 54.771 43.1393 54.9022 43.0983 55.0554L43.0509 55.2321C43.0283 55.3165 42.9767 55.348 42.8962 55.3264L42.26 55.1559C42.2423 55.1512 42.2292 55.1413 42.2206 55.1264C42.2139 55.112 42.2127 55.0969 42.2169 55.0812L42.2232 55.0577C42.2274 55.042 42.236 55.0295 42.249 55.0204C42.2639 55.0117 42.2802 55.0098 42.2979 55.0145L42.9164 55.1803L42.959 55.0212C42.989 54.9093 42.9776 54.8147 42.9246 54.7373C42.8722 54.6581 42.793 54.6042 42.6869 54.5758C42.5809 54.5474 42.4844 54.5541 42.3974 54.5961C42.3129 54.6366 42.2556 54.7128 42.2256 54.8247L42.0899 55.3313C42.0599 55.4432 42.0711 55.5388 42.1235 55.6181C42.1784 55.696 42.2579 55.7488 42.362 55.7767C42.468 55.8051 42.563 55.8001 42.647 55.7616C42.7335 55.7216 42.7912 55.6476 42.8201 55.5396C42.8243 55.5239 42.8329 55.5115 42.8459 55.5023C42.8608 55.4937 42.8762 55.4915 42.8919 55.4957L42.9154 55.502C42.9331 55.5067 42.9453 55.5163 42.9519 55.5307C42.9606 55.5457 42.9628 55.561 42.9586 55.5767C42.9161 55.7274 42.8374 55.8326 42.7225 55.8923C42.6081 55.95 42.4763 55.9589 42.3271 55.9189C42.1778 55.8789 42.0664 55.8038 41.9928 55.6936C41.9217 55.5819 41.9067 55.4495 41.9477 55.2964L42.085 54.7839Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.0138 50.9666C38.7421 51.1234 38.6491 51.4707 38.8059 51.7424C38.9627 52.014 39.31 52.107 39.5816 51.9502L40.8614 51.2114L41.6003 52.4911C41.7571 52.7628 42.1044 52.8558 42.376 52.699C42.6476 52.5422 42.7407 52.1949 42.5839 51.9233L41.845 50.6435L43.1251 49.9044C43.3967 49.7476 43.4898 49.4003 43.3329 49.1287C43.1761 48.857 42.8288 48.764 42.5572 48.9208L41.2771 49.6599L40.5381 48.3798C40.3812 48.1082 40.0339 48.0151 39.7623 48.172C39.4907 48.3288 39.3976 48.6761 39.5545 48.9477L40.2935 50.2277L39.0138 50.9666Z"
            fill="url(#paint92_linear)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.014 50.9666C38.7423 51.1235 38.6493 51.4708 38.8061 51.7424C38.9629 52.014 39.3102 52.1071 39.5818 51.9503L40.8618 51.2113L41.6008 52.4913C41.7576 52.7629 42.1049 52.8559 42.3765 52.6991C42.6482 52.5423 42.7412 52.195 42.5844 51.9234L42.0075 50.9242C41.9583 50.8964 41.9001 50.8726 41.8284 50.8533C41.5819 50.7873 41.4605 50.8321 41.3598 50.8693C41.2887 50.8955 41.228 50.918 41.1408 50.8946C41.0537 50.8713 40.9926 50.8085 40.921 50.7349C40.8198 50.6308 40.6976 50.5053 40.4511 50.4392C40.1944 50.3704 39.9772 50.4294 39.8468 50.4858L39.014 50.9666Z"
            fill="url(#paint93_linear)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.014 50.9666C38.7424 51.1235 38.6494 51.4708 38.8062 51.7424C38.963 52.014 39.3103 52.1071 39.5819 51.9503L40.8619 51.2113L41.6009 52.4912C41.7577 52.7629 42.105 52.8559 42.3766 52.6991C42.6482 52.5423 42.7413 52.195 42.5845 51.9234L42.3431 51.5053C42.1659 51.4202 42.0822 51.3544 42.0328 51.2689C42.0328 51.2689 41.9013 50.918 41.4805 50.8052C41.2341 50.7392 41.0616 50.8013 40.9187 50.8527C40.8176 50.8891 40.7314 50.9202 40.6442 50.8968C40.5571 50.8735 40.5138 50.8309 40.463 50.7811C40.3913 50.7106 40.3047 50.6255 40.0582 50.5594C39.9436 50.5287 39.8314 50.5318 39.7289 50.5539L39.014 50.9666Z"
            fill="url(#paint94_linear)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.0141 50.9666C38.7425 51.1235 38.6494 51.4708 38.8062 51.7424C38.963 52.014 39.3104 52.1071 39.582 51.9503L40.8619 51.2113L41.6009 52.4913C41.7577 52.7629 42.1051 52.8559 42.3767 52.6991C42.6483 52.5423 42.7413 52.195 42.5845 51.9234L42.3946 51.5944C42.3884 51.5942 42.3823 51.5941 42.3761 51.5941C42.0952 51.595 41.9272 51.437 41.8783 51.3579C41.8783 51.3579 41.6989 51.0289 41.2665 50.9763C40.9887 50.9425 40.8419 50.9943 40.7148 51.0393C40.6441 51.0643 40.5794 51.0871 40.5017 51.0918C40.2843 51.1051 40.138 51.0998 39.7864 50.9002C39.658 50.8272 39.5003 50.7824 39.3579 50.7681L39.0141 50.9666Z"
            fill="url(#paint95_linear)"
          />
        </g>
        <path
          d="M38.5661 67.215C38.3162 68.1477 37.3548 68.7049 36.4201 68.4545C35.4853 68.204 34.9312 67.2413 35.1813 66.3081C35.4313 65.3749 36.3925 64.8182 37.3273 65.0686C38.2621 65.3191 38.8162 66.2818 38.5661 67.215Z"
          fill="#26363F"
        />
      </g>
      <defs>
        <filter
          id="filter90_d"
          x="32.637"
          y="4.01695"
          width="61.6017"
          height="98.287"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.33898" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint90_linear"
          x1="72.0294"
          y1="16.0678"
          x2="72.0294"
          y2="79"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#81D1FF" />
          <stop offset="1" stopColor="#DAEFFB" />
        </linearGradient>
        <linearGradient
          id="paint91_linear"
          x1="35.7208"
          y1="35.3631"
          x2="48.4746"
          y2="69.2491"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C7EAB" />
          <stop offset="0.479167" stopColor="#3CACEB" />
          <stop offset="1" stopColor="#24D9FF" />
        </linearGradient>
        <linearGradient
          id="paint92_linear"
          x1="41.104"
          y1="49.9802"
          x2="40.1328"
          y2="50.5481"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.58" />
        </linearGradient>
        <linearGradient
          id="paint93_linear"
          x1="39.9813"
          y1="50.4017"
          x2="42.3559"
          y2="51.5849"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#24D9FF" />
        </linearGradient>
        <linearGradient
          id="paint94_linear"
          x1="38.1763"
          y1="50.6159"
          x2="42.196"
          y2="52.0237"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#24D9FF" stopOpacity="0.2" />
          <stop offset="1" stopColor="#17E0FB" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id="paint95_linear"
          x1="38.8277"
          y1="50.8954"
          x2="42.6171"
          y2="52.4569"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="0.177083" stopColor="#3CACEB" stopOpacity="0.2" />
        </linearGradient>
        <clipPath id="clip0">
          <path d="M0.36438 0H143.636V79H0.36438V0Z" fill="white" />
        </clipPath>
        <clipPath id="clip1">
          <rect
            x="35.4457"
            y="36.3898"
            width="18.0685"
            height="25.5085"
            rx="2.12571"
            transform="rotate(15 35.4457 36.3898)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
