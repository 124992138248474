import { useState, useEffect } from "react";

const useIsTabletOrSmaller = () => {
  const [isTabletOrSmaller, setIsTabletOrSmaller] = useState(false);

  useEffect(() => {
    const checkDeviceType = () => {
      setIsTabletOrSmaller(window.innerWidth <= 768);
    };

    checkDeviceType();

    window.addEventListener("resize", checkDeviceType);
    return () => window.removeEventListener("resize", checkDeviceType);
  }, []);

  return isTabletOrSmaller;
};

export default useIsTabletOrSmaller;
