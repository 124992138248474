import { NextComponentType, NextPageContext } from "next";
import { ReactElement } from "react";
import Navbar from "core/navbar/Navbar";
import { ScreenSizes } from "core/home/types";
import { Media } from "lib/media";
import AppWrapper from "core/home/AppWrapper";

interface Props {
  Component: NextComponentType<NextPageContext, any, Record<string, unknown>>;
  pageProps: any;
  err: any;
}

export function SSRAppContainer({
  Component,
  pageProps,
  err,
}: Props): ReactElement {
  return (
    <div>
      {<Navbar />}
      {/* @ts-ignore */}
      <Media at="xs">
        <AppWrapper
          screenSize={ScreenSizes.XS}
          Component={Component}
          pageProps={pageProps}
          err={err}
        />
      </Media>
      {/* @ts-ignore */}
      <Media at="sm">
        <AppWrapper
          screenSize={ScreenSizes.SM}
          Component={Component}
          pageProps={pageProps}
          err={err}
        />
      </Media>
      {/* @ts-ignore */}
      <Media at="md">
        <AppWrapper
          screenSize={ScreenSizes.MD}
          Component={Component}
          pageProps={pageProps}
          err={err}
        />
      </Media>
      {/* @ts-ignore */}
      <Media at="lg">
        <AppWrapper
          screenSize={ScreenSizes.LG}
          Component={Component}
          pageProps={pageProps}
          err={err}
        />
      </Media>
      {/* @ts-ignore */}
      <Media greaterThanOrEqual="xl">
        <AppWrapper
          screenSize={ScreenSizes.XL}
          Component={Component}
          pageProps={pageProps}
          err={err}
        />
      </Media>
    </div>
  );
}
