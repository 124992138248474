import {
  CredentialInformation,
  CredentialFormInputParams,
  BackendPayloadKey,
  CredentialFamily,
  CredentialInformationRaw,
  CredentialFormInputParamsRaw,
  InputValueType,
  EndpointInformationRaw,
  EndpointInformation,
  SuccessScreenRaw,
  SuccessScreen,
  ErrorOptionRaw,
  ErrorOption,
  ErrorButton,
  ErrorButtonTypes,
  KeyboardType,
  BodyDescription,
  BackendTypeEnum,
  CaptureLayout,
} from "core/credentials/types";
import { UploadStatus } from "./types";
import {
  getFormattedTitleFromSupported,
  sortDropdownTitles,
} from "core/credentials/helpers";
import { ServiceEndpoints } from "lib/http/constants";
import { ByStringId } from "lib/types/common";
import { AppValues } from "lib/context/AppContext";

export interface ServerCredResponse {
  country_alpha_2: string;
  onboarding: string[];
  supported_credentials: string[];
  current_user_credentials: string[];
  credentials_manifest: CredentialInformationRaw[];
  organization_required_credentials: ByStringId<string[]>;
}

export function decodeCredentialList(options: {
  rawContext: ServerCredResponse;
  setAppValues: (value: Partial<AppValues>) => void;
}): void {
  try {
    const { rawContext, setAppValues } = options;

    const onboardingCreds = rawContext.onboarding ?? [];
    const supportedCredentialTitles = rawContext.supported_credentials ?? [];
    // const credInfo: ByStringId<CredentialInformation> = {};
    const credInfo = decodeCredentialInformation(
      rawContext.credentials_manifest
    );
    // for (const [_, cred] of Object.entries(rawContext.credentials_manifest)) {
    //   const credentialType = formatBackendTypeEnum(cred.credential_type);
    //   const allowPhoto = checkAllowPhoto(cred);
    //   const output: CredentialInformation = {
    //     // if allow_photo is explicit use that (TODO add that field to all on backend)
    //     // cred.camera_prompt_header_default === null is less explicit, but works
    //     allowPhoto: allowPhoto,
    //     onboardPromptText: cred.onboard_prompt_text ?? null,
    //     onboardHeaderText: cred.onboard_header_text ?? null,
    //     additionalInfoText: cred.additional_info_text ?? null,
    //     cameraPromptHeaderOnboard: cred.camera_prompt_header_onboard ?? null,
    //     cameraPromptHeaderDefault: cred.camera_prompt_header_default,
    //     cameraPromptHeaderDescription: cred.camera_prompt_header_description,
    //     cameraPromptBodyDescription: formatBodyDescription(cred.camera_prompt_body_description),
    //     imageSource: cred.image_source,
    //     layout: cred.layout === 'landscape' ? CaptureLayout.LANDSCAPE : CaptureLayout.PORTRAIT,
    //     cameraHeader: cred.camera_header,
    //     credentialType: credentialType,
    //     credentialFamily: cred.credential_family as CredentialFamily,
    //     screenTitle: cred.screen_title,
    //     title: cred.title,
    //     showVerified: cred.show_10ure_verified ?? false,
    //     successScreen: formatSuccessScreen(cred.success_screen),
    //     dropdownName: cred.dropdown_name,
    //     inputs: formatInputs(cred.inputs),
    //     errors: cred.errors ? formatErrors(cred.errors) : null,
    //     uploadStatus: UploadStatus.UNKNOWN,
    //     endpoints: formatEndpoints({endpoints: cred.endpoints}),
    //     modifyEndpoint: formatModifyEndpoint(cred.modify_endpoint),
    //     credentialRefId: null,
    //   }
    //   // Format title to match names in onboardingCreds and supportedCredentialTitles
    //   const formattedTitle = formatTitle(cred.credential_type)
    //   credInfo[`${formattedTitle}`] = output;
    // }
    /**
     * @section1 Regular supported credentials
     */
    const fomattedTitles = getFormattedTitleFromSupported(
      supportedCredentialTitles,
      credInfo
    );
    const sortedTitles = sortDropdownTitles(fomattedTitles);
    /**
     * @section2 Organization supported credentials
     */
    const formattedOrganizationRequiredCredentials: ByStringId<string[]> = {};
    for (const [orgName, credNames] of Object.entries(
      rawContext.organization_required_credentials
    )) {
      const fomattedOrgTitles = getFormattedTitleFromSupported(
        credNames,
        credInfo
      );
      const sortedTitles = sortDropdownTitles(fomattedOrgTitles);
      formattedOrganizationRequiredCredentials[orgName] = sortedTitles;
    }
    /**
     * @section2 Already Added credentials
     */
    const currentUserCredentials = getFormattedTitleFromSupported(
      rawContext.current_user_credentials ?? [],
      credInfo
    );

    setAppValues({
      countryAlpha2: rawContext.country_alpha_2 ?? "",
      credentials: JSON.parse(JSON.stringify(credInfo)),
      currentUserCredentials: currentUserCredentials ?? [],
      formattedTitles: [...sortedTitles],
      onboardingCredentials: onboardingCreds.length ? [...onboardingCreds] : [],
      organizationRequiredCredentials: formattedOrganizationRequiredCredentials,
      supportedCredentialTitles: sortedTitles.length ? [...sortedTitles] : [],
    });
  } catch (e: any) {
    console.warn(`Error decoding credential object: ${e?.message}`);
    throw new Error(`Failed to decode credentials object: ${e?.message}`);
  }
}

export function decodeCredentialInformation(
  credentials_manifest: CredentialInformationRaw[]
): ByStringId<CredentialInformation> {
  const credInfo: ByStringId<CredentialInformation> = {};
  for (const [, cred] of Object.entries(credentials_manifest)) {
    const credentialType = formatBackendTypeEnum(cred.credential_type);
    const allowPhoto = checkAllowPhoto(cred);
    const output: CredentialInformation = {
      additionalInfoText: cred.additional_info_text ?? null,
      // if allow_photo is explicit use that (TODO add that field to all on backend)
      // cred.camera_prompt_header_default === null is less explicit, but works
      allowPhoto: allowPhoto,
      cameraHeader: cred.camera_header,
      cameraPromptBodyDescription: formatBodyDescription(
        cred.camera_prompt_body_description
      ),
      cameraPromptHeaderDefault: cred.camera_prompt_header_default,
      cameraPromptHeaderDescription: cred.camera_prompt_header_description,
      cameraPromptHeaderOnboard: cred.camera_prompt_header_onboard ?? null,
      credentialFamily: cred.credential_family as CredentialFamily,
      credentialRefId: null,
      credentialType: credentialType,
      dropdownName: cred.dropdown_name,
      endpoints: formatEndpoints({ endpoints: cred.endpoints }),
      errors: cred.errors ? formatErrors(cred.errors) : null,
      imageSource: cred.image_source,
      inputs: formatInputs(cred.inputs),
      layout:
        cred.layout === "landscape"
          ? CaptureLayout.LANDSCAPE
          : CaptureLayout.PORTRAIT,
      modifyEndpoint: formatModifyEndpoint(cred.modify_endpoint),
      onboardHeaderText: cred.onboard_header_text ?? null,
      onboardPromptText: cred.onboard_prompt_text ?? null,
      screenTitle: cred.screen_title,
      showVerified: cred.show_10ure_verified ?? false,
      successScreen: formatSuccessScreen(cred.success_screen),
      title: cred.title,
      uploadStatus: UploadStatus.UNKNOWN,
    };
    // Format title to match names in onboardingCreds and supportedCredentialTitles
    const formattedTitle = formatTitle(cred.credential_type);
    credInfo[`${formattedTitle}`] = output;
  }
  return credInfo;
}

/**
 *
 * @param options:
 * @param endpoints - if endpoints - return uri and map service
 * @returns EndpointInformation array w/ uri and service
 */
function formatEndpoints(options: {
  endpoints: EndpointInformationRaw[];
}): EndpointInformation[] {
  const { endpoints } = options;
  return endpoints.map((endpoint) => {
    return {
      service: formatService(endpoint.service),
      uri: endpoint.uri,
    };
  });
}
/**
 *
 * @param options:
 * @param endpoints - if endpoints - return uri and map service
 * @returns EndpointInformation array w/ uri and service
 */
function formatModifyEndpoint(
  endpoint: EndpointInformationRaw
): EndpointInformation {
  return {
    service: formatService(endpoint?.service ?? "maritimecredentialing"),
    uri: endpoint?.uri ?? "",
  };
}

function formatService(service: string): ServiceEndpoints {
  switch (service.toLowerCase()) {
    case "auth":
      return ServiceEndpoints.AUTH;
    case "mariner":
      return ServiceEndpoints.MARINER;
    case "maritimecredentialing":
    default:
      return ServiceEndpoints.MARITIMECREDENTIALING;
    case "jobs":
      return ServiceEndpoints.MARINER;
    case "seatime":
      return ServiceEndpoints.SEATIME;
    case "notify":
      return ServiceEndpoints.NOTIFY;
    case "org":
      return ServiceEndpoints.ORG;
    case "deeplink":
      return ServiceEndpoints.DEEPLINK;
    case "news":
      return ServiceEndpoints.NEWS;
  }
}

// Note - if one of the fields exist all will - but may be empty object, if so return null;
function formatSuccessScreen(
  screenInfo: SuccessScreenRaw | null
): SuccessScreen | null {
  if (screenInfo && screenInfo.additional_text) {
    return {
      additionalText: screenInfo.additional_text,
      buttonText: screenInfo.button_text,
      text: screenInfo.text,
      title: screenInfo.title,
    };
  }
  return null;
}

function checkAllowPhoto(data: CredentialInformationRaw) {
  if (data.allow_photo) {
    return data.allow_photo;
  }
  if (
    !data.camera_prompt_header_default ||
    data.camera_prompt_header_default === null
  ) {
    return false;
  }
  return true;
}

function formatBodyDescription(val: string): BodyDescription {
  switch (val) {
    case "Focus on the bottom half of your MMC Document as shown below":
      return BodyDescription.MMC;
    case "Focus on the bottom half of passport as shown below":
      return BodyDescription.PASSPORT;
    case "Focus on the entire document as shown below":
    default:
      return BodyDescription.ENTIRE_DOCUMENT;
  }
}

function formatInputs(
  inputArray: CredentialFormInputParamsRaw[]
): CredentialFormInputParams[] {
  try {
    return inputArray.map((input) => {
      const formattedInput: CredentialFormInputParams = {
        allCaps: input.all_caps ?? false,
        backendPayloadKey: formatBackendPayloadKey(input.payload_key),
        inputDisplayText: input.display_text,
        inputKeyboardType: input.keyboard_type as KeyboardType,
        inputPlaceholder: input.placeholder,
        inputTempValue: undefined,
        inputValue: input.value === "undefined" ? undefined : input.value,
        inputValueType: input.value_type as InputValueType,
      };
      return formattedInput;
    });
  } catch (e: any) {
    throw new Error(`Failed to decode inputObject: ${e?.message}`);
  }
}

/**
 * Handle any v2 as camel to snake conversions don't work properly
 */
function formatBackendPayloadKey(value: string): BackendPayloadKey | string {
  if (value === "reference_number_v_2") {
    return BackendPayloadKey.REFERENCE_NUMBER_V2;
  }
  return value as BackendPayloadKey | string;
}

/**
 * Note - temporary just output it as types, but in future I think we
 * need to actually dynamically CREATE thte BackendTypeEnum for later screens
 * @param credentialType - backend credential type
 * @returns BackendTypeEnum
 */
function formatBackendTypeEnum(credentialType: string): BackendTypeEnum {
  return credentialType as BackendTypeEnum;
}

/**
 * Format title to match names in onboardingCreds and supportedCredentialTitles
 * need ph_mmc --> coc/cop , otherwise just replace space with _
 */
function formatTitle(credentialType: string): string {
  // const formattedTitle = credentialType.toLowerCase().replace(" ", "_")
  const formattedTitle = credentialType.toLowerCase().split(" ").join("_");
  if (formattedTitle === "ph_mmc") {
    return "coc/cop";
  }
  return formattedTitle;
}

function formatErrors(
  errorsArr: ErrorOptionRaw[]
): ByStringId<ErrorOption> | null {
  if (!errorsArr || errorsArr?.length === 0) {
    return null;
  }
  try {
    const outputObj: ByStringId<ErrorOption> = {};
    errorsArr.map((error) => {
      const formattedError: ErrorOption = {
        buttons: formatErrorButtons(error.buttons),
        description: error.description,
        descriptionLink: error.description_link ?? null,
        imageUrl: error.image_url ?? null,
        title: error.title,
      };
      outputObj[`${error.status_code}`] = formattedError;
    });
    return outputObj;
  } catch (e: any) {
    throw new Error(`Failed to decode errorArray: ${e?.message}`);
  }
}

function formatErrorButtons(errorButtonsArray: ErrorButton[]): ErrorButton[] {
  try {
    return errorButtonsArray.map((button) => {
      const formattedButton: ErrorButton = {
        title: button.title,
        type: button.type as ErrorButtonTypes,
      };
      return formattedButton;
    });
  } catch (e: any) {
    throw new Error(`Failed to decode errorArray: ${e?.message}`);
  }
}
