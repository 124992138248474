import { ScreenSizes } from "core/home/types";
import { AppValues } from "lib/context/AppContext";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

// export enum ScreenSizes {
//   XS = "xs", // 0
//   SM = "sm", // 450
//   MD = "md", // 768
//   LG = "lg", // 1024
//   XL = "xl", // 1500
// }
// TODO - switch this to an enum with keyof Breakpoint
// const breakpoints: Breakpoints = {
//   '>320': '321px',
//   '>480': '481px',
//   '>768': '769px',
//   '>1024': '1025px',
//   '>1200': '1201px',
//   '>1400': '1401px',
// };

function getScreenSize(options: {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
}): ScreenSizes {
  const { xs, sm, md, lg } = options;
  if (xs) {
    return ScreenSizes.XS;
  }
  if (sm) {
    return ScreenSizes.SM;
  }
  if (md) {
    return ScreenSizes.MD;
  }
  if (lg) {
    return ScreenSizes.LG;
  }
  return ScreenSizes.XL;
}
export function useSetScreenSize(
  setAppValues: (value: Partial<AppValues>) => void
) {
  const xs = useMediaQuery({ query: `(max-width: 450px)` });
  const sm = useMediaQuery({ query: "(max-width: 768px)" });
  const md = useMediaQuery({ query: "(max-width: 1024px)" });
  const lg = useMediaQuery({ query: "(max-width: 1500px)" });

  useEffect(() => {
    const screenSize = getScreenSize({ lg, md, sm, xs });
    setAppValues({ screenSize });
  }, [xs, sm, md, lg, setAppValues]);
}
