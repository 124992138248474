import { DefaultProfilePhotoIcon } from "lib/svgs/DefaultProfilePhoto";
import Image from "next/image";
import { ReactElement } from "react";
import styled from "styled-components";
import { VerifiedLogoLarge, VerifiedLogoSmall } from "lib/svgs/VerifiedLogo";
import { TextAndShapesPalette } from "lib/styles/colors";

interface ImageSizeProps {
  size: string;
}
const ImageContainer = styled.div<ImageSizeProps>`
  ${({ size }) => `
    width: ${size}px;
    height: ${size}px;
  `}
  border-radius: 80px;
  position: relative;
`;

const InnerImageContainer = styled.div<ImageSizeProps>`
  ${({ size }) => `
  width: ${size}px;
  height: ${size}px;
  `}
  margin-right: 12px;
  border-radius: 80px;
  overflow: hidden;
  box-sizing: border-box;
`;

const VerifiedBadge = styled.div<ImageSizeProps>`
  position: absolute;
  ${({ size }) => `
  right: ${size}px;
  bottom: ${size}px;
  `}
`;

export enum ImageSize {
  TINY = "tiny",
  XS_SMALL = "xs_small",
  SMALL = "small",
  NORMAL = "normal",
  LARGE = "large",
}

interface Props {
  imageUrl?: string | null;
  size?: ImageSize;
  is10ureVerified?: boolean;
}

export function getViewSizeFromEnum(size?: ImageSize): {
  containerSize: string;
  imageSize: string;
} {
  switch (size) {
    case ImageSize.TINY:
      return {
        containerSize: "50",
        imageSize: "50",
      };
    case ImageSize.XS_SMALL:
      return {
        containerSize: "72",
        imageSize: "72",
      };
    case ImageSize.SMALL:
      return {
        containerSize: "80",
        imageSize: "80",
      };
    case ImageSize.NORMAL:
    default:
      return {
        containerSize: "100",
        imageSize: "100",
      };
    case ImageSize.LARGE:
      return {
        containerSize: "160",
        imageSize: "160",
      };
  }
}

function getVerifiedSizeFromEnum(size?: ImageSize): {
  logoSize: string;
  offset: string;
} {
  switch (size) {
    case ImageSize.TINY:
      return {
        logoSize: "12",
        offset: "0",
      };
    case ImageSize.SMALL:
      return {
        logoSize: "24",
        offset: "0",
      };
    case ImageSize.NORMAL:
    default:
      return {
        logoSize: "48",
        offset: "0",
      };
    case ImageSize.LARGE:
      return {
        logoSize: "64",
        offset: "-4",
      };
  }
}

export default function ProfilePhotoView({
  imageUrl,
  size,
  is10ureVerified,
}: Props): ReactElement {
  const { containerSize, imageSize } = getViewSizeFromEnum(size);
  const { offset } = getVerifiedSizeFromEnum(size);
  const verifiedBadge =
    size === ImageSize.LARGE ? <VerifiedLogoLarge /> : <VerifiedLogoSmall />;
  if (!imageUrl) {
    return (
      <ImageContainer size={containerSize}>
        <InnerImageContainer size={containerSize}>
          <DefaultProfilePhotoIcon
            width={`${imageSize}px`}
            height={`${imageSize}px`}
          />
          {is10ureVerified && (
            <VerifiedBadge size={offset}>{verifiedBadge}</VerifiedBadge>
          )}
        </InnerImageContainer>
      </ImageContainer>
    );
  }
  return (
    <ImageContainer size={containerSize}>
      <InnerImageContainer
        size={containerSize}
        style={{
          borderColor: `${TextAndShapesPalette.PLACEHOLDER}`,
          borderWidth: "1.2px",
        }}
      >
        <Image
          // style={{
          //   borderColor: `${TextAndShapesPalette.PLACEHOLDER}`,
          //   borderWidth: "3px",
          // }}
          src={imageUrl}
          width={`${imageSize}px`}
          height={`${imageSize}px`}
        />
        {is10ureVerified && (
          <VerifiedBadge size={offset}>{verifiedBadge}</VerifiedBadge>
        )}
      </InnerImageContainer>
    </ImageContainer>
  );
}
