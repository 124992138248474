import { CurrentNav } from "lib/context/AppContext";

export const getCurrentNav = (pathname: string): CurrentNav => {
  if (pathname.includes("company-directory")) {
    return CurrentNav.COMPANY;
  }
  if (pathname.includes("dashboard") || pathname.includes("job")) {
    return CurrentNav.JOB;
  }
  if (pathname.includes("notification")) {
    return CurrentNav.NOTIFICATION;
  }
  return CurrentNav.NONE;
};

// const KNOWN_BASE_PATHS = ["/news", "/jobs", "/jobs-test"];

export function isUnknownPath(pathname: string): boolean {
  if (pathname === "/") {
    return false;
  }
  return false;
  // for (const element of KNOWN_BASE_PATHS) {
  //   if (element.includes(pathname)) {
  //     return false;
  //   }
  // }
  // return true;
}
