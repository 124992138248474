import { AuthResponse } from "core/auth/types";
import { useCallback, useEffect, useState } from "react";
import { getWebRedirectKey } from "core/auth/service";
import { ResponseStatus } from "lib/http/types";
import { getCookie } from "lib/vanillaCookie";
import { AppValues } from "lib/context/AppContext";

export function useWebRedirectKey(options: {
  isLoggedIn: boolean;
  destination: string;
  setAppValues: (values: Partial<AppValues>) => void;
}) {
  const { isLoggedIn, destination, setAppValues } = options;
  const [authResp, setAuthResp] = useState<AuthResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const callGetWebRedirectKey = useCallback(async () => {
    setIsLoading(true);
    const result = await getWebRedirectKey(destination);
    if (result.status === ResponseStatus.ERROR) {
      setError(result.error);
    } else {
      setAuthResp(result.body);
      setAppValues({ exchangeToken: result.body.token });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const currentToken = getCookie(document.cookie, "userToken");
    if (currentToken && isLoggedIn) {
      callGetWebRedirectKey();
    }
  }, [isLoggedIn]);

  return {
    authResp,
    error,
    isLoading,
  };
}
