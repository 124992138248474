import { createMedia } from "@artsy/fresnel";

const AppMedia = createMedia({
  breakpoints: {
    lg: 1024,
    md: 800,
    sm: 450,
    xl: 1500,
    xs: 0,
  },
});

// Make styles for injection into the header of the page
export const mediaStyles = AppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = AppMedia;
