import { ReactNode } from "react";

export type ById<T> = { [id: number]: T };
export type ByStringId<T> = { [id: string]: T };

export enum CookieKeys {
  USER_REF_ID = "userRefId",
  EMAIL_PROMPT_ATTEMPTED = "emailNewsletterAttempted",
  EMAIL_PROMPT_SUBSCRIBED = "emailNewsletterSubscribed",
  NEWS_VIEWED = "newsViewed",
  JOBS_VIEWED = "jobsViewed",
  TRACKING_CONTEXT = "trackingContext",
}

export type ReactChildren = ReactNode | Array<ReactNode>;

export enum Z_INDEX {
  BELOW = "-1",
  REGULAR = "0",
  ABOVE = "1",
  TOP = "1000",
  VERY_TOP = "10000",
}
