import React from "react";

const BASE_WIDTH = 18;
const BASE_HEIGHT = 14;

export default function HamburgerIcon(props: {
  height?: number;
  width?: number;
}) {
  const width = props.width || BASE_WIDTH;
  const height = props.height || BASE_HEIGHT;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13H17M1 1H17H1ZM1 7H17H1Z"
        stroke="#4D4D4D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
