import { checkDateIsZeroValue } from "lib/checkDateIsZeroValue";

import { ByStringId } from "lib/types/common";
import { Credential, CredentialInformation } from "./types";
import { FullUserCredentialingDetails } from "core/profile/types";

export function formatExpirationDate(expDate?: string | null): string | null {
  if (!expDate || checkDateIsZeroValue(expDate)) {
    return null;
  }
  return expDate.split("T")[0];
}

export function getCredentialsArrayFromUserDetails(
  candidate: FullUserCredentialingDetails | null
): Credential[] {
  let credentials: Credential[] = [];
  if (!candidate) {
    return credentials;
  }
  if (candidate.documents && candidate.documents.length > 0) {
    credentials = [...credentials, ...candidate.documents];
  }
  if (candidate.certificates && candidate.certificates.length > 0) {
    credentials = [...credentials, ...candidate.certificates];
  }
  if (candidate.identifications && candidate.identifications.length > 0) {
    credentials = [...credentials, ...candidate.identifications];
  }
  return credentials;
}

export function sortCredentialsByExpDate(
  credsArray: Credential[]
): Credential[] {
  return credsArray.sort((a: Credential, b: Credential) => {
    const hasExpA = a.expirationDate && !checkDateIsZeroValue(a.expirationDate);
    const hasExpB = b.expirationDate && !checkDateIsZeroValue(b.expirationDate);

    if (hasExpB && !hasExpA) {
      return 1;
    }
    if (hasExpA && hasExpB) {
      if (String(a.expirationDate) < String(b.expirationDate)) {
        return -1;
      }
      return 1;
    }
    return -1;
  });
}

export function getFormattedTitleFromSupported(
  supportedCredentialTitles: string[],
  credentials: ByStringId<CredentialInformation>
): string[] {
  if (!supportedCredentialTitles) {
    return [];
  }

  return supportedCredentialTitles.map((title: string) => {
    return credentials[`${title}`]?.dropdownName;
  });
}

export function sortDropdownTitles(dropdownNames: string[]): string[] {
  if (!dropdownNames || dropdownNames.length === 0) {
    return [];
  }
  return dropdownNames.sort((a, b) =>
    a.localeCompare(b, "fr", { ignorePunctuation: true })
  );
}
