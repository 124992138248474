import { PrimaryColorPalette, TextAndShapesPalette } from "lib/styles/colors";
import React from "react";
import styled from "styled-components";

interface BurderStyleProps {
  open: boolean;
}
export const StyledBurger = styled.button<BurderStyleProps>`
  padding: 8px 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 2.5rem;
  background: ${PrimaryColorPalette.MAIN_6};
  border: none;
  cursor: pointer;
  z-index: 10;

  &:focus {
    outline: none;
  }

  :first-child {
    opacity: ${({ open }) => (open ? "0" : "1")};

    transform: ${({ open }) => (open ? "rotate(91deg)" : "rotate(1)")};
  }

  :nth-child(2) {
    opacity: ${({ open }) => (open ? "0" : "1")};
  }

  :nth-child(3) {
    opacity: ${({ open }) => (open ? "0" : "1")};
  }
`;

const BurgerLine = styled.div`
  width: 2rem;
  height: 0.25rem;
  background: ${TextAndShapesPalette.INPUT};
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
`;

interface Props {
  open: boolean;
  handleToggleNav: () => void;
}

const Burger = ({ open, handleToggleNav }: Props) => {
  return (
    <StyledBurger open={open} onClick={handleToggleNav}>
      <BurgerLine />
      <BurgerLine />
      <BurgerLine />
    </StyledBurger>
  );
};

export default Burger;
