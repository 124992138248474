import React from "react";

const BASE_WIDTH = 52;
const BASE_HEIGHT = 56;

export default function NavBrandLogo(props: {
  height?: number;
  width?: number;
}) {
  const width = props.width || BASE_WIDTH;
  const height = props.height || BASE_HEIGHT;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${BASE_WIDTH} ${BASE_HEIGHT}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00224 39.6501V54.9703C7.00224 55.2272 6.99421 55.3798 6.97815 55.428C6.9621 55.4601 6.8818 55.4761 6.73727 55.4761H6.03871C5.92629 55.4761 5.85403 55.4521 5.82191 55.4039C5.80585 55.3396 5.79782 55.2593 5.79782 55.163V40.0355L1.65462 43.3115C1.44585 43.4721 1.2692 43.456 1.12467 43.2633L0.907877 42.9983C0.843641 42.9181 0.811523 42.8297 0.811523 42.7334C0.811523 42.637 0.883789 42.5326 1.02832 42.4202L5.17152 39.0478C5.54088 38.7588 5.91023 38.6143 6.27959 38.6143H6.32777C6.77742 38.6303 7.00224 38.9756 7.00224 39.6501Z"
        fill="#24D9FF"
      />
      <path
        d="M15.525 38.6143H15.8141C17.1309 38.6143 18.239 38.9836 19.1383 39.7223C20.0376 40.461 20.4872 41.6173 20.4872 43.1911V50.6344C20.4872 52.2403 20.0536 53.4527 19.1865 54.2717C18.3193 55.0747 17.1711 55.4922 15.7418 55.5243H15.5973C14.152 55.4922 12.9957 55.0747 12.1286 54.2717C11.2614 53.4527 10.8278 52.2403 10.8278 50.6344V43.1911C10.8278 42.1312 11.0606 41.2479 11.5263 40.5413C12.3614 39.2566 13.6943 38.6143 15.525 38.6143ZM15.8623 39.7705H15.4768C14.5294 39.7705 13.7184 40.0355 13.0439 40.5654C12.3694 41.0793 12.0322 41.9786 12.0322 43.2633V50.6103C12.0322 53.1155 13.1965 54.3681 15.525 54.3681H15.8141C18.1426 54.3681 19.3069 53.1155 19.3069 50.6103V43.2633C19.3069 41.9786 18.9697 41.0793 18.2952 40.5654C17.6207 40.0355 16.8097 39.7705 15.8623 39.7705Z"
        fill="#24D9FF"
      />
      <path
        d="M24.3766 43.3838H24.5693C24.8904 43.3838 25.051 43.5444 25.051 43.8655V51.4775C25.051 52.4249 25.2839 53.1396 25.7496 53.6213C26.2314 54.1031 26.9058 54.344 27.773 54.344C28.6402 54.344 29.387 53.9987 30.0133 53.3082C30.1578 53.1315 30.3103 53.0432 30.4709 53.0432C30.7761 53.0432 30.9286 53.2199 30.9286 53.5732C30.9286 53.7177 30.8885 53.8462 30.8082 53.9586C30.487 54.4082 30.0373 54.7776 29.4592 55.0666C28.8811 55.3396 28.2709 55.4761 27.6285 55.4761C26.5204 55.4761 25.6211 55.1309 24.9306 54.4403C24.2561 53.7337 23.9189 52.7542 23.9189 51.5016V43.8655C23.9189 43.5444 24.0714 43.3838 24.3766 43.3838ZM31.5067 43.4319H31.7476C32.0527 43.4319 32.2053 43.5925 32.2053 43.9137V52.6578C32.2053 53.2841 32.2535 53.7177 32.3498 53.9586C32.4622 54.1834 32.671 54.336 32.9761 54.4163L33.2411 54.4885C33.482 54.5367 33.6024 54.6411 33.6024 54.8017V55.0666C33.6024 55.3396 33.4418 55.4761 33.1207 55.4761C32.3338 55.4761 31.7878 55.2433 31.4826 54.7776C31.1936 54.3119 31.0491 53.5972 31.0491 52.6337V43.9137C31.0491 43.5925 31.2016 43.4319 31.5067 43.4319Z"
        fill="#24D9FF"
      />
      <path
        d="M39.987 43.3838C40.2921 43.3838 40.4447 43.5444 40.4447 43.8655V44.0341C40.4447 44.1626 40.3965 44.275 40.3001 44.3714C40.2198 44.4677 40.1154 44.5159 39.987 44.5159C39.0716 44.5159 38.3409 44.7488 37.7949 45.2145C37.2489 45.6802 36.9759 46.3948 36.9759 47.3583V54.9944C36.9759 55.1389 36.9277 55.2593 36.8314 55.3557C36.735 55.436 36.6226 55.4761 36.4942 55.4761H36.3255C36.1971 55.4761 36.0847 55.436 35.9883 55.3557C35.8919 55.2593 35.8438 55.1389 35.8438 54.9944V47.3583C35.8438 46.0415 36.2131 45.0539 36.9518 44.3955C37.6906 43.721 38.7023 43.3838 39.987 43.3838Z"
        fill="#24D9FF"
      />
      <path
        d="M42.9021 47.3583C42.9021 46.0897 43.2875 45.1101 44.0583 44.4196C44.8291 43.729 45.8328 43.3838 47.0693 43.3838C48.3059 43.3838 49.3015 43.7371 50.0563 44.4437C50.8111 45.1342 51.1885 46.1058 51.1885 47.3583V48.8037C51.1885 49.4942 50.8592 49.8395 50.2008 49.8395H44.9977C44.8532 49.8395 44.7328 49.7913 44.6364 49.6949C44.5561 49.5986 44.516 49.4862 44.516 49.3577V49.165C44.516 49.0365 44.5561 48.9241 44.6364 48.8277C44.7328 48.7314 44.8532 48.6832 44.9977 48.6832H50.0563V47.3824C50.0563 46.4671 49.7753 45.7685 49.2132 45.2867C48.6511 44.7889 47.9365 44.54 47.0693 44.54C46.2022 44.54 45.4795 44.7889 44.9014 45.2867C44.3393 45.7685 44.0583 46.4671 44.0583 47.3824V51.5256C44.0583 52.441 44.3393 53.1476 44.9014 53.6454C45.4795 54.1272 46.1941 54.3681 47.0453 54.3681C47.9124 54.3681 48.6271 54.1352 49.1891 53.6695C49.7672 53.1877 50.0563 52.5052 50.0563 51.622C50.0563 51.4935 50.0965 51.3811 50.1768 51.2848C50.2731 51.1884 50.3855 51.1402 50.514 51.1402H50.7067C50.8512 51.1402 50.9636 51.1884 51.0439 51.2848C51.1403 51.3811 51.1885 51.4935 51.1885 51.622C51.1724 52.8585 50.787 53.8221 50.0322 54.5126C49.2775 55.1871 48.2898 55.5243 47.0693 55.5243C45.8489 55.5243 44.8452 55.1791 44.0583 54.4885C43.2875 53.7819 42.9021 52.8023 42.9021 51.5497V47.3583Z"
        fill="#24D9FF"
      />
      <path
        d="M18.1367 1.73093C16.3848 -0.0209853 13.5444 -0.0209858 11.7924 1.73093C10.0405 3.48284 10.0405 6.32324 11.7924 8.07515L20.0478 16.3305L11.7925 24.5859C10.0405 26.3378 10.0405 29.1782 11.7925 30.9301C13.5444 32.682 16.3848 32.682 18.1367 30.9301L26.392 22.6747L34.6474 30.9301C36.3993 32.682 39.2397 32.682 40.9916 30.9301C42.7435 29.1782 42.7435 26.3378 40.9916 24.5859L32.7363 16.3305L40.9916 8.07516C42.7435 6.32325 42.7435 3.48284 40.9916 1.73093C39.2397 -0.0209793 36.3993 -0.0209797 34.6474 1.73093L26.392 9.98629L18.1367 1.73093Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M34.625 18.2185L40.9926 24.5862C42.7445 26.3381 42.7445 29.1785 40.9926 30.9304C39.2407 32.6823 36.4003 32.6823 34.6484 30.9304L26.393 22.675L18.1377 30.9304C16.3857 32.6823 13.5453 32.6823 11.7934 30.9304C10.0415 29.1785 10.0415 26.3381 11.7934 24.5862L17.7151 18.6645C18.6459 18.1212 19.9262 17.623 21.4911 17.623C23.5069 17.623 24.6961 18.3315 25.6813 18.9185C26.3779 19.3335 26.9725 19.6877 27.6852 19.6877C28.3979 19.6877 28.8154 19.3925 29.3045 19.0467C29.9962 18.5576 30.8312 17.9672 32.8469 17.9672C33.5574 17.9672 34.1304 18.0588 34.625 18.2185Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M38.3367 21.9315L40.9916 24.5864C42.7435 26.3383 42.7435 29.1787 40.9916 30.9306C39.2397 32.6825 36.3993 32.6825 34.6474 30.9306L26.392 22.6753L18.1367 30.9306C16.3848 32.6825 13.5444 32.6825 11.7924 30.9306C10.0405 29.1787 10.0405 26.3383 11.7924 24.5864L16.5006 19.8783C17.1558 19.5537 17.9045 19.3439 18.7372 19.3439C20.753 19.3439 21.5879 19.8162 22.2796 20.2075C22.7687 20.4842 23.1862 20.7203 23.8989 20.7203C24.6116 20.7203 25.2062 20.307 25.9028 19.8229C26.888 19.1381 28.0772 18.3115 30.093 18.3115C33.5341 18.3115 35.2547 20.7203 35.2547 20.7203C35.8337 21.2993 36.6562 21.6347 38.3367 21.9315Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M38.8363 22.4307L40.9916 24.586C42.7435 26.3379 42.7435 29.1783 40.9916 30.9302C39.2397 32.6821 36.3993 32.6821 34.6474 30.9302L26.392 22.6749L18.1367 30.9302C16.3848 32.6821 13.5444 32.6821 11.7924 30.9302C10.0405 29.1783 10.0405 26.3379 11.7924 24.586L14.0438 22.3346C15.1064 22.1895 16.3059 22.225 17.3607 22.4995C20.4516 23.3042 21.5788 23.0449 23.2107 22.4995C23.7941 22.3045 24.2406 21.9981 24.7291 21.6627C25.607 21.06 26.6209 20.3641 28.8099 20.0541C32.2171 19.5717 34.2584 21.7155 34.2584 21.7155C34.793 22.2191 36.3979 23.0808 38.5399 22.4995C38.6374 22.473 38.7363 22.4502 38.8363 22.4307Z"
        fill="url(#paint3_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="25.7261"
          y1="12.786"
          x2="19.4771"
          y2="19.1043"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0ED4FF" />
          <stop offset="1" stopColor="#2DFFF2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="20.2341"
          y1="18.4488"
          x2="39.3818"
          y2="21.3609"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#24D9FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="19.3071"
          y1="20.4757"
          x2="41.6646"
          y2="25.7395"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3CACEB" stopOpacity="0.8" />
          <stop offset="1" stopColor="#17E0FB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="14.7446"
          y1="24.0408"
          x2="47.1665"
          y2="28.8701"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0ED4FF" />
          <stop offset="0.177083" stopColor="#3CACEB" />
        </linearGradient>
      </defs>
    </svg>
  );
}
