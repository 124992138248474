import {
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  IconButton,
} from "@chakra-ui/react";
import Text, { TextType } from "components/Text_DEPRECATED";
import HamburgerIcon from "lib/svgs/Hamburger";

interface OwnProps {
  isUser?: boolean;
  handleAccount: () => void;
  handleSupport: () => void;
  handleLogout: () => void;
}

export default function MobileNavbarAccountMenu(props: OwnProps): JSX.Element {
  const { isUser, handleAccount, handleSupport, handleLogout } = props;

  return (
    <>
      <Menu flip>
        <MenuButton
          zIndex="10"
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          size="xs"
          variant="none"
        >
          {isUser ? (
            <MenuList zIndex="10">
              <MenuItem minH="45px" minWidth="266px" onClick={handleAccount}>
                <Text type={TextType.PARAGRAPH}>Account</Text>
              </MenuItem>
              <MenuItem minH="45px" minWidth="266px" onClick={handleSupport}>
                <Text type={TextType.PARAGRAPH}>Customer Support</Text>
              </MenuItem>
              <MenuItem minH="45px" minWidth="266px" onClick={handleLogout}>
                <Text type={TextType.PARAGRAPH}>Sign Out</Text>
              </MenuItem>
            </MenuList>
          ) : (
            <MenuList zIndex="4">
              <MenuItem minH="45px" minWidth="266px" onClick={handleLogout}>
                Sign Out
              </MenuItem>
            </MenuList>
          )}
        </MenuButton>
      </Menu>
    </>
  );
}
