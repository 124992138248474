import { useEffect, useState } from "react";

export function useIsFocused(): { isFocused: boolean } {
  const [isFocused, setIsFocused] = useState<boolean>(true);

  const handleFocused = () => {
    setIsFocused(true);
  };

  const handleBlurred = () => {
    isFocused && setIsFocused(false);
  };

  useEffect(() => {
    window.addEventListener("focus", handleFocused);
    window.addEventListener("blur", handleBlurred);
    return () => {
      window.removeEventListener("focus", handleFocused);
      window.removeEventListener("blur", handleBlurred);
    };
  }, []);

  return { isFocused };
}
