import Button, { ButtonType } from "components/Button";
import { TextType } from "components/Text_DEPRECATED";
import LoginFlowModalWrapper from "core/auth/LoginFlowModalWrapper";
import NavBrandLogoSmall from "lib/svgs/NavBrandLogoSmall";
import SendAppToPhoneModal from "components/SendAppToPhoneModal";
import SendAppToPhoneButton from "../SendAppToPhoneButton";
import Burger from "../Burger";
import { useEffect, useState } from "react";
import MobileNavPopUpModal from "./MobileNavPopUpModal";
import { useRouter } from "next/router";
import { ForceCreateModalType } from "lib/context/AppContext";
import styled from "styled-components";
// import NavbarAccountMenu from "core/navbar/NavbarAccountMenu";
// import ButtonLink from "components/ButtonLink";

// const POST_JOB_REDIRECT = 'https://dashboard.10ure.com?origin=website&redirect=myjobs';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const NavIconButton = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const SendAppToPhoneButtonDiv = styled.div`
  :hover {
    cursor: pointer;
  }
  margin-left: 8px;
`;

interface OwnProps {
  userRefId?: string;
  phoneNumber: string | null;
  isPhone: boolean;
  isTablet: boolean;
  handleGoHome: () => void;
  loginModalEnabled: boolean;
  path: string;
  forceCreateAccountModal: ForceCreateModalType | null;
  showSendAppToPhoneButton: boolean;
  handleRefreshAuth: (isFirstTimer?: boolean) => void;
  handleCloseModal: () => void;
  handleSignIn: () => void;
}

export default function MobileNavbar(props: OwnProps): JSX.Element {
  const router = useRouter();
  const [isClient, setIsClient] = useState(false);
  const pathname = router && router.pathname ? router.pathname : "";
  const [navigationModalOpen, setNavigationModalOpen] =
    useState<boolean>(false);
  const {
    userRefId,
    phoneNumber,
    isTablet,
    path,
    loginModalEnabled,
    forceCreateAccountModal,
    showSendAppToPhoneButton,
    handleCloseModal,
    handleGoHome,
    handleRefreshAuth,
    handleSignIn,
  } = props;

  useEffect(() => {
    setIsClient(true);
  }, []);
  const handleToggleNav = () => {
    setNavigationModalOpen(!navigationModalOpen);
  };
  return (
    <>
      {/* // ts-ignore */}
      <Container>
        <LeftSide>
          <NavIconButton onClick={handleGoHome}>
            <NavBrandLogoSmall />
          </NavIconButton>
          {showSendAppToPhoneButton && isTablet && (
            <SendAppToPhoneButtonDiv onClick={handleGoHome}>
              <SendAppToPhoneButton userRefId={userRefId} path={path} />
            </SendAppToPhoneButtonDiv>
          )}
        </LeftSide>
        <RightSide>
          <>
            {isClient && !userRefId && (
              <Button
                textDecoration="none"
                marginRight="8px"
                styleType={ButtonType.PRIMARY_BLACK}
                textType={TextType.BUTTON}
                onClick={handleSignIn}
              >
                Sign In
              </Button>
            )}
            <Burger
              open={navigationModalOpen}
              handleToggleNav={handleToggleNav}
            />
          </>
        </RightSide>
      </Container>
      <LoginFlowModalWrapper
        isOpen={loginModalEnabled}
        refreshAuthAndClose={handleRefreshAuth}
        onCancel={handleCloseModal}
      />
      <SendAppToPhoneModal
        currPhone={phoneNumber}
        header={
          forceCreateAccountModal === ForceCreateModalType.NEWS
            ? "View On 10ureApp"
            : "Apply On 10ure App"
        }
        handleToggleModal={handleCloseModal}
        modalEnabled={forceCreateAccountModal}
        mobile={true}
      />
      <MobileNavPopUpModal
        modalEnabled={navigationModalOpen}
        handleClose={handleToggleNav}
        currentPathname={pathname}
        handleSignIn={handleSignIn}
        userRefId={userRefId}
        path={path}
        showSendAppToPhoneButton={showSendAppToPhoneButton}
      />
    </>
  );
}
