import React, { ReactElement, useState } from "react";
import { Flex } from "@chakra-ui/react";
import PhoneIcon from "lib/svgs/PhoneIcon";
import Text, { TextType } from "components/Text_DEPRECATED";
import SendAppToPhoneModal from "components/SendAppToPhoneModal";
import {
  AnalyticsEvents,
  InternalAnalyticsEvents,
} from "lib/analytics/constants";
import { track } from "lib/analytics/analytics";
import { ForceCreateModalType, useAppValues } from "lib/context/AppContext";
import { useTracking } from "core/tracking/useTracking";

interface Props {
  userRefId?: string;
  path?: string;
  underline?: boolean;
  header?: string;
}
export default function SendAppToPhoneButton({
  userRefId,
  path,
  underline,
  header,
}: Props): ReactElement {
  const [modalEnabled, toggleModalEnabled] = useState<boolean>(false);
  const { getAppValues, setAppValues } = useAppValues();
  const { phone, enableSendToPhoneModalOnLaunch } = getAppValues();
  const { internalTrackEvent } = useTracking();

  const handleOpenModal = () => {
    internalTrackEvent(InternalAnalyticsEvents.SEND_APP_TO_PHONE_CLICKED);
    track(AnalyticsEvents.APPLY_IN_APP_CLICKED, {
      isUser: Boolean(userRefId),
      userRefId: userRefId,
    });
    toggleModalEnabled(true);
  };

  const closeModal = () => {
    if (enableSendToPhoneModalOnLaunch) {
      setAppValues({ enableSendToPhoneModalOnLaunch: false });
    }
    toggleModalEnabled(false);
  };

  return (
    <>
      <Flex
        cursor="pointer"
        flexDir="row"
        alignItems="center"
        marginRight="24px"
        minW="200px"
        onClick={handleOpenModal}
      >
        <PhoneIcon />
        <Text
          type={TextType.HYPERLINKS}
          textDecoration={underline ? "underline" : undefined}
        >
          Send app to your phone
        </Text>
      </Flex>
      <SendAppToPhoneModal
        path={path}
        modalEnabled={
          modalEnabled || enableSendToPhoneModalOnLaunch
            ? ForceCreateModalType.GENERIC
            : null
        }
        currPhone={phone}
        handleToggleModal={closeModal}
        header={header ?? "Apply On 10ure App"}
      />
    </>
  );
}
