import { useMediaQuery } from "@chakra-ui/react";
import { BackgroundColorPalette, PrimaryColorPalette } from "lib/styles/colors";
import { useRouter } from "next/router";
import styled from "styled-components";

interface StyleProps {
  setNoMax: boolean;
  isSmall: boolean;
  backgroundColor: string;
}

const StyledFullPageWrapper = styled.div<StyleProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100vh;
  ${({ backgroundColor }) => `
  background-color: ${
  backgroundColor
    ? backgroundColor
    : BackgroundColorPalette.BACKGROUND_LIGHT_GRAY
};
  `};
  ${({ isSmall }) => `
    height: ${isSmall ? "100%" : "100vh"};
  `};
`;

interface OwnProps {
  children: React.ReactNode;
}

export function formatBackgroundColorByPath(pathname?: string): string {
  if (!pathname) {
    return BackgroundColorPalette.BACKGROUND_LIGHT_GRAY;
  }
  if (pathname.includes("account") || pathname.includes("support")) {
    return PrimaryColorPalette.MAIN_6;
  }
  return BackgroundColorPalette.BACKGROUND_LIGHT_GRAY;
}

/**
 * A default container for pages to give a consistent width
 * and padding
 * @param props - and page children
 */
export default function NonSSRPageContainer(props: OwnProps): JSX.Element {
  const router = useRouter();
  const [isSmall] = useMediaQuery(["(max-width: 769px)"]);
  const noMax =
    router.pathname.includes("jobs") || router.pathname.includes("v2");
  const isHome = router.pathname === "/";
  const setNoMax = isHome || noMax || isSmall;
  const formattedBackgroundColor = formatBackgroundColorByPath(router.pathname);

  return (
    <StyledFullPageWrapper
      backgroundColor={formattedBackgroundColor}
      isSmall={isSmall}
      setNoMax={setNoMax}
    >
      {props.children}
    </StyledFullPageWrapper>
  );
}
