import { useEffect, useState } from "react";
import {
  ServerCredResponse,
  decodeCredentialList,
} from "core/credentials/payload";
import { MethodType, ResponseStatus } from "lib/http/types";
import { AppValues } from "lib/context/AppContext";
import { call } from "lib/http/call";
import { ServiceEndpoints } from "lib/http/constants";

interface SetContextParams {
  refreshContext?: boolean;
  setAppValues: (value: Partial<AppValues>) => void;
  userRefId: string | null;
}

export function useCredentialContext(options: SetContextParams) {
  const { setAppValues, refreshContext, userRefId } = options;
  const [formatted, setFormatted] = useState<any>();
  const [error, setError] = useState<string | null>(null);

  const callContext = async (): Promise<boolean> => {
    const contextRes = await call({
      endpoint: `${ServiceEndpoints.MARITIMECREDENTIALING}/v1/app/context/v2`, // "app/context",
      method: MethodType.GET,
      noTranslateToCamel: true,
    });

    if (contextRes.status === ResponseStatus.ERROR) {
      setFormatted(null);
      setAppValues({
        contextLoading: false,
        refreshContext: false,
      });
      setError("Error getting context");
      return false;
    }

    decodeCredentialList({
      rawContext: contextRes.body as ServerCredResponse,
      setAppValues,
    });
    setFormatted(contextRes.body);
    setAppValues({
      contextLoading: false,
      refreshContext: false,
    });
    return true;
  };

  useEffect(() => {
    if (userRefId) {
      callContext();
    } else {
      setAppValues({
        contextLoading: false,
        refreshContext: false,
      });
    }
  }, [userRefId]);

  useEffect(() => {
    if (refreshContext) {
      callContext();
    }
  }, [refreshContext]);

  return {
    context: formatted,
    error,
  };
}
