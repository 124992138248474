import Button, { ButtonType } from "components/Button";
import LoginFlowModalWrapper from "core/auth/LoginFlowModalWrapper";
import { TextType } from "components/Text_DEPRECATED";
import NavBrandLogo from "lib/svgs/NavBrandLogo";
import { useRouter } from "next/router";
import { useState } from "react";
import NavbarButton from "core/navbar/NavbarButton";
import MobileNavbar from "core/navbar/mobile/MobileNavbar";
import NavbarAccountMenu, {
  BASE_CREW_URL,
} from "core/navbar/NavbarAccountMenu";
import { parseCookies } from "nookies";
import { ForceCreateModalType, useAppValues } from "lib/context/AppContext";
import * as gtag from "lib/analytics/gtag";
import {
  AnalyticsEvents,
  InternalAnalyticsEvents,
} from "lib/analytics/constants";
import { ScreenSizes } from "core/home/types";
import { track } from "lib/analytics/analytics";
import SendAppToPhoneButton from "./SendAppToPhoneButton";
import SendAppToPhoneModal from "components/SendAppToPhoneModal";
import { useTracking } from "core/tracking/useTracking";
import styled from "styled-components";
import { TextAndShapesPalette } from "lib/styles/colors";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 106px;
  max-height: 106px;
  width: 100hh;
  padding-left: 40px;
  padding-right: 40px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-bottom-width: 1px;
  border-bottom-color: ${TextAndShapesPalette.INPUT_FIELD};
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const RightSide = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 16px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 60px;
  align-items: center;
`;

const LogoButton = styled.button`
  width: 150px;
  height: 100px;
  background: none;
  border: none;
  cursor: pointer;
`;
enum CurrentNav {
  JOBS = "job",
  NEWS = "news",
  NONE = "none",
}

// const POST_JOB_REDIRECT = "https://www.10ure.com/post-jobs-on-10ure"; // old flow - to old 10ure marketing page
export const POST_JOB_REDIRECT = "https://deeplink.10ure.com/web/addjob/flow";

const getCurrentNav = (pathName: string): CurrentNav => {
  if (pathName === "" || pathName.includes("job")) {
    return CurrentNav.JOBS;
  }
  if (pathName.includes("news")) {
    return CurrentNav.NEWS;
  }
  return CurrentNav.NONE;
};

export default function Navbar(): JSX.Element {
  const router = useRouter();
  const { getAppValues, setAppValues } = useAppValues();
  const {
    forceCreateAccountModal,
    screenSize,
    showSendAppToPhoneButton,
    phone,
    userRefId: appValUsrRefId,
    exchangeToken,
  } = getAppValues();
  const showProductNavButtons = false; //useMediaQuery({ query: "(min-width: 1250px)" });
  const isPhone =
    screenSize === ScreenSizes.XS || screenSize === ScreenSizes.SM;
  const isTablet = screenSize === ScreenSizes.MD;
  const currentNav = getCurrentNav(router.pathname);
  const cookies = parseCookies();
  const userRefId = cookies.userRefId;
  // console.log("userRefId: ", userRefId);
  const { internalTrackEvent } = useTracking();
  const path = currentNav === CurrentNav.NEWS ? "/news" : "/jobs";
  const [loginModalEnabled, setLoginModalEnabled] = useState<boolean>(false);
  // const [finishSignupModalEnabled, setFinishSignupModalEnabled] =
  //   useState<boolean>(false);
  // Note - for login instead of "send to phone"
  // useEffect(() => {
  //   if (forceCreateAccountModal && !loginModalEnabled) {
  //     setAppValues({ forceCreateAccountModal: null });
  //   }
  // }, [forceCreateAccountModal, setAppValues]);
  // console.log(
  //   "userRefId || appValUsrRefId: ",
  //   userRefId,
  //   " appVal: ",
  //   appValUsrRefId
  // );

  const handleGoHome = () => {
    // Don't let them redirect home unless a plan exists though
    router.push("/jobs");
  };

  const handleSignIn = () => {
    gtag.event({
      action: "navbar_clicked_sign_in",
      category: "engagement",
    });
    internalTrackEvent(InternalAnalyticsEvents.NAV_CLICK_JOB_SEEKER_SIGN_IN, {
      path: path,
    });

    track(AnalyticsEvents.NAVBAR_CLICK_SIGN_IN);
    setLoginModalEnabled(true);
  };

  const handleCloseModal = () => {
    setLoginModalEnabled(false);
    // setFinishSignupModalEnabled(false);
    if (forceCreateAccountModal) {
      setAppValues({ forceCreateAccountModal: null });
    }
  };

  const handleRefreshAuth = () => {
    //isFirstTimer?: boolean) => {
    console.warn("refresh auth");
    setLoginModalEnabled(false);
    if (forceCreateAccountModal) {
      setAppValues({ forceCreateAccountModal: null });
    }
    // if firstTimer and "signed IN" instead of "signedup" , open finish sign up modal
    // if (isFirstTimer) {
    //   setFinishSignupModalEnabled(false); // true
    // }
  };

  const handleTrackProfileClick = () => {
    gtag.event({
      action: "navbar_click_profile_tab",
      category: "engagement",
    });
    internalTrackEvent(InternalAnalyticsEvents.NAVBAR_CLICK_PROFILE_TAB);
    track(AnalyticsEvents.NAVBAR_CLICK_PROFILE_TAB);
  };

  const handleTrackJobsClick = () => {
    gtag.event({
      action: "navbar_click_jobs_tab",
      category: "engagement",
    });
    internalTrackEvent(InternalAnalyticsEvents.NAVBAR_CLICK_JOBS_TAB);
    track(AnalyticsEvents.NAVBAR_CLICK_JOBS_TAB);
  };

  const handleTrackNewsClick = () => {
    gtag.event({
      action: "navbar_click_news_tab",
      category: "engagement",
    });
    internalTrackEvent(InternalAnalyticsEvents.NAVBAR_CLICK_NEWS_TAB);
    track(AnalyticsEvents.NAVBAR_CLICK_NEWS_TAB);
  };

  const handleTrackProductsClick = () => {
    gtag.event({
      action: "navbar_click_products_tab",
      category: "engagement",
    });
    internalTrackEvent(InternalAnalyticsEvents.NAVBAR_CLICK_PRODUCTS_TAB);
    track(AnalyticsEvents.NAVBAR_CLICK_PRODUCTS_TAB);
  };

  const handleTrackAboutUsClick = () => {
    gtag.event({
      action: "navbar_click_about_us_tab",
      category: "engagement",
    });
    internalTrackEvent(InternalAnalyticsEvents.NAVBAR_CLICK_ABOUT_US_TAB);
    track(AnalyticsEvents.NAVBAR_CLICK_ABOUT_US_TAB);
  };

  // const handleTrackPostJobClick = () => {
  //   gtag.event({
  //     action: "navbar_click_post_new_job",
  //     category: "engagement",
  //   });
  //   internalTrackEvent(InternalAnalyticsEvents.NAVBAR_CLICK_POST_NEW_JOB);
  //   track(AnalyticsEvents.NAVBAR_CLICK_POST_NEW_JOB);
  // };

  if (isPhone || isTablet) {
    return (
      <MobileNavbar
        userRefId={userRefId}
        phoneNumber={phone}
        isPhone={isPhone}
        isTablet={isTablet}
        handleGoHome={handleGoHome}
        loginModalEnabled={loginModalEnabled}
        showSendAppToPhoneButton={showSendAppToPhoneButton}
        path={path}
        forceCreateAccountModal={forceCreateAccountModal}
        handleRefreshAuth={handleRefreshAuth}
        handleCloseModal={handleCloseModal}
        handleSignIn={handleSignIn}
      />
    );
  }

  return (
    <Container>
      <InnerContainer>
        <LogoButton onClick={handleGoHome}>
          <NavBrandLogo />
        </LogoButton>
        <ButtonGroup>
          {userRefId && (
            <NavbarButton
              title="Dashboard"
              attrTitle="Martime User Profile"
              focused={false}
              link={`${BASE_CREW_URL}/crew/${userRefId}/redirect?code=${exchangeToken}`}
              handleTrack={handleTrackProfileClick}
            />
          )}
          {/* nav text buttons */}
          <NavbarButton
            title="Jobs"
            attrTitle="Martime Jobs And Career Advancement"
            focused={currentNav === CurrentNav.JOBS}
            link="/jobs"
            handleTrack={handleTrackJobsClick}
          />
          <NavbarButton
            title="News"
            attrTitle="Martime News. Stay on top of latest mariner news alerts."
            focused={currentNav === CurrentNav.NEWS}
            link="/news"
            handleTrack={handleTrackNewsClick}
          />
          {Boolean(showProductNavButtons) && (
            <>
              <NavbarButton
                title="Products"
                focused={false}
                attrTitle="Products 10ure. Maritime Jobs, Mariner Talent Acquisition, Maritime Credential Management"
                link="https://www.product.10ure.com"
                small={true}
                newTab
                handleTrack={handleTrackProductsClick}
              />
              <NavbarButton
                title="About Us"
                focused={false}
                attrTitle="For Mariners Built By Mariners. Designers, Engineers And Management, 10ure Team"
                link="https://www.product.10ure.com/about-us"
                small={true}
                newTab
                handleTrack={handleTrackAboutUsClick}
              />
            </>
          )}
        </ButtonGroup>
      </InnerContainer>

      <RightSide>
        {showSendAppToPhoneButton && (
          <SendAppToPhoneButton userRefId={userRefId} path={path} />
        )}
        {/* <ButtonLink
          textDecoration='none'
          marginRight='1rem'
          // button to right looks slightly bigger so increase height 1
          height='65px'
          styleType={ButtonType.CANCEL_SECONDARY_BLACK}
          textType={TextType.BUTTON}
          href={POST_JOB_REDIRECT}
          onClick={handleTrackPostJobClick}
        >
          Post Jobs for Free
        </ButtonLink> */}
        {userRefId || appValUsrRefId ? (
          <NavbarAccountMenu userRefId={userRefId || appValUsrRefId} />
        ) : (
          <Button
            textDecoration="none"
            styleType={ButtonType.PRIMARY_BLACK}
            textType={TextType.BUTTON}
            onClick={handleSignIn}
          >
            Job Seeker Sign In
          </Button>
        )}
      </RightSide>
      <LoginFlowModalWrapper
        isOpen={loginModalEnabled} // || forceCreateAccountModal}
        refreshAuthAndClose={handleRefreshAuth}
        onCancel={handleCloseModal}
      />
      {/* <FinishSignupModalWrapper
        isOpen={finishSignupModalEnabled} // || forceCreateAccountModal}
        refreshAuthAndClose={handleRefreshAuth}
        onCancel={handleCloseModal}
      /> */}
      <SendAppToPhoneModal
        currPhone={phone}
        header={
          forceCreateAccountModal === ForceCreateModalType.NEWS
            ? "View On 10ureApp"
            : "Apply On 10ure App"
        }
        handleToggleModal={handleCloseModal}
        modalEnabled={forceCreateAccountModal}
        mobile={false}
      />
    </Container>
  );
}
