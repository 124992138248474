import { useRouter } from "next/router";
import { useEffect } from "react";
import * as gtag from "lib/analytics/gtag";

const isProduction = process.env.NEXT_PUBLIC_ENV === "production";

export default function useTrackPage() {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      if (isProduction) {
        window.analytics.page(url);
        gtag.pageview(url);
      }
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
}
