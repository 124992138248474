import { useAppValues } from "lib/context/AppContext";
import { useRouter } from "next/router";
import { ReactElement, useEffect } from "react";
import { NextComponentType, NextPageContext } from "next";
import { useIsFocused } from "lib/hooks/useIsFocused";
import { useIsMounted } from "lib/hooks/useIsMounted";
import PageContainer from "components/PageContainer";
import { parseCookies } from "nookies";
import useTrackPage from "lib/analytics/useTrackPage";
import useEmailToast from "lib/hooks/useEmailToast";
import { ScreenSizes } from "./types";
import useWebContext from "core/auth/useWebContext";
import { getCurrentNav, isUnknownPath } from "./helpers";
import styled from "styled-components";
import { BackgroundColorPalette } from "lib/styles/colors";
import { formatBackgroundColorByPath } from "./NonSSRPageContainer";
import { useAuthByQueryParams } from "core/account/useAuthByQueryParams";

interface StyleProps {
  backgroundColor?: string;
}
const CenteredContainer = styled.div<StyleProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  ${({ backgroundColor }) => `
  background-color: ${
  backgroundColor
    ? backgroundColor
    : BackgroundColorPalette.BACKGROUND_LIGHT_GRAY
};
`}
`;

interface OwnProps {
  Component: NextComponentType<NextPageContext, any>;
  pageProps: any;
  err: any;
  screenSize: ScreenSizes;
}

export default function AppWrapper(props: OwnProps): ReactElement {
  const { Component, pageProps, err, screenSize } = props;
  const router = useRouter();
  useTrackPage();
  // TODO - cant use my CookieKeys.___ enum here - need to look into
  const { userRefId, emailNewsletterAttempted, emailNewsletterSubscribed } =
    parseCookies(null);
  // const isNotPhone =
  //   screenSize !== ScreenSizes.XS && screenSize !== ScreenSizes.SM;
  // const [showFooter, setShowFooter] = useState<boolean>(false);
  const { getAppValues, setAppValues } = useAppValues();
  useAuthByQueryParams(setAppValues);
  const { appFocused } = getAppValues();
  const { isFocused } = useIsFocused();
  const isMounted = useIsMounted();
  useEmailToast(userRefId, emailNewsletterAttempted, emailNewsletterSubscribed);
  useWebContext(setAppValues);
  const formattedBackgroundColor = formatBackgroundColorByPath(router.pathname);

  // todo: this should prob be ".includes"
  useEffect(() => {
    if (!router.isReady) return;
    // const enableFooter =
    //   !userRefId &&
    //   isNotPhone &&
    //   (router.pathname === "/jobs" || router.pathname.includes("news"));
    // if (enableFooter) {
    //   setShowFooter(true);
    // }
    // coming from product page, show send app to phone modal on launch
    if (
      router.pathname === "/jobs" &&
      router.query?.sendapptophone === "true"
    ) {
      setAppValues({ enableSendToPhoneModalOnLaunch: true });
      // remove query param -- note this is erroring so commenting out for now
      // router.replace("/jobs", undefined, { shallow: true });
    }

    const currentNav = getCurrentNav(router.pathname);
    if (currentNav) {
      setAppValues({
        currentNav: currentNav,
      });
    }
  }, [router.isReady, router.pathname]);

  // Set screen size on load
  useEffect(() => {
    setAppValues({ screenSize: screenSize });
  }, []);

  useEffect(() => {
    if (!userRefId) {
      // clearDataForLogout();
      const currentPath = router.pathname;
      if (isUnknownPath(currentPath)) {
        router.push("/jobs");
      }
    }
  }, [userRefId]);

  useEffect(() => {
    if (!appFocused && isFocused) {
      isMounted.current && setAppValues({ appFocused: true });
      return;
    }
    if (appFocused && !isFocused) {
      isMounted.current && setAppValues({ appFocused: false });
    }
  }, [isFocused]);

  /**
   * TEMP ugly workaround weird JSX element tsc error
   */
  const AnyComponent = Component as any;
  return (
    <>
      <CenteredContainer backgroundColor={formattedBackgroundColor}>
        <PageContainer>
          <AnyComponent {...pageProps} err={err} />
        </PageContainer>
      </CenteredContainer>
      {/* {Boolean(showFooter) && <Footer path={router.pathname} />} */}
    </>
  );
}
