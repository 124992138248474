import { NewsFeedArticle } from "core/feed/types";
import React, { createContext, useContext } from "react";

export interface FeedValues {
  feed: NewsFeedArticle[] | null;
  query: string | null;
  hasMutated: boolean;
}

export const initialValues: FeedValues = {
  feed: null,
  hasMutated: false,
  query: null,
};

type FeedValuesAction = {
  type: "feed/SET";
  value: Partial<FeedValues>;
};

function feedReducer(state: FeedValues, action: FeedValuesAction): FeedValues {
  switch (action.type) {
    case "feed/SET": {
      return {
        ...state,
        ...action.value,
      };
    }

    default:
      return state;
  }
}

export interface FeedProviderValue {
  setFeedValues: (value: Partial<FeedValues>) => void;
  getFeedValues: () => FeedValues;
}

const FeedContext = createContext<FeedProviderValue | null>(null);

export function useFeedValues() {
  const context = useContext(FeedContext);
  if (!context) {
    throw new Error("useFeedValues() must be used within a FeedProvider.");
  }
  return context;
}

type Props = {
  [k in keyof FeedValues]?: FeedValues[k];
} & { children: React.ReactNode };

export function FeedProvider(props: Props) {
  const { children, ...otherProps } = props;
  const [feedState, feedDispatch] = React.useReducer<
  React.Reducer<FeedValues, FeedValuesAction>
  >(feedReducer, {
    ...initialValues,
    ...otherProps,
  });
  const getFeedValues = React.useCallback((): FeedValues => {
    return feedState;
  }, [feedState]);
  const setFeedValues = React.useCallback(
    (value: Partial<FeedValues>): void => {
      feedDispatch({ type: "feed/SET", value });
    },
    [feedDispatch]
  );

  return (
    <FeedContext.Provider value={{ getFeedValues, setFeedValues }}>
      {children}
    </FeedContext.Provider>
  );
}
