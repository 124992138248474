import { RenderProps, useToast } from "@chakra-ui/react";
import EmailSubscriptionPopUp from "components/EmailSubscriptionPopUp";
import { useEffect, useRef } from "react";

const TOAST_TIMEOUT = 12500; // 12.5 seconds

export default function useEmailToast(
  userRefId?: string | null,
  emailNewsletterAttempted?: string | null,
  emailNewsletterSubscribed?: string | null
) {
  const toast = useToast();
  const id = useRef<string>("email-toast");
  const timeoutId = useRef<NodeJS.Timeout>();

  const toastAfterTimeout = (userRefId?: string | null) => {
    timeoutId.current = setTimeout(() => {
      if (!toast.isActive(id.current)) {
        toast({
          duration: null,
          id: id.current,
          position: "bottom-right",
          render: (props: RenderProps) => (
            <EmailSubscriptionPopUp userRefId={userRefId} {...props} />
          ),
        });
      }
    }, TOAST_TIMEOUT);
  };

  useEffect(() => {
    if (emailNewsletterSubscribed) {
      return;
    }
    // TODO - do a date comparison instead .. if > 30 days or something
    if (!emailNewsletterAttempted) {
      toastAfterTimeout(userRefId);
    }
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [userRefId, emailNewsletterAttempted, emailNewsletterSubscribed]);
}
