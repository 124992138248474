import { useAppValues } from "lib/context/AppContext";
import { useRouter } from "next/router";
import { ReactElement, useEffect } from "react";
import { NextComponentType, NextPageContext } from "next";
import Navbar from "core/navbar/Navbar";
import { useIsFocused } from "lib/hooks/useIsFocused";
import { useIsMounted } from "lib/hooks/useIsMounted";
import { parseCookies } from "nookies";
import useTrackPage from "lib/analytics/useTrackPage";
import useEmailToast from "lib/hooks/useEmailToast";
import useWebContext from "core/auth/useWebContext";
import { getCurrentNav } from "./helpers";
import NonSSRPageContainer from "./NonSSRPageContainer";
import { useSetScreenSize } from "lib/hooks/useSetScreenSize";
import { useAuthByQueryParams } from "core/account/useAuthByQueryParams";

interface Props {
  Component: NextComponentType<NextPageContext, any>;
  pageProps: any;
  err: any;
}

// Temp - Don't SSR Since we are using mediaQuery for sizing
export function SafeHydrate({ children }: { children: any }) {
  return (
    <div
      suppressHydrationWarning
      // Should be doing the below, but getting an error where it isnt toggling and is left on hidden
      // style={{
      //   visibility: typeof window === "undefined" ? "hidden" : "visible",
      // }}
    >
      {/* {typeof window === "undefined" ? null : children} */}
      {children}
    </div>
  );
}

export default function NonSSRAppWrapper(props: Props): ReactElement {
  const { Component, pageProps, err } = props;
  const router = useRouter();
  useTrackPage();
  // TODO - cant use my CookieKeys.___ enum here - need to look into
  const { userRefId, emailNewsletterAttempted, emailNewsletterSubscribed } =
    parseCookies(null);
  // const isNotPhone =
  //   screenSize !== ScreenSizes.XS && screenSize !== ScreenSizes.SM;
  // const [showFooter, setShowFooter] = useState<boolean>(false);
  const { getAppValues, setAppValues } = useAppValues();
  const { appFocused } = getAppValues();
  const { isFocused } = useIsFocused();
  const isMounted = useIsMounted();
  const { isLoading } = useAuthByQueryParams(setAppValues);

  useSetScreenSize(setAppValues);
  useEmailToast(userRefId, emailNewsletterAttempted, emailNewsletterSubscribed);
  useWebContext(setAppValues);

  useEffect(() => {
    if (!router.isReady) return;
    const currentNav = getCurrentNav(router.pathname);
    if (currentNav) {
      isMounted.current &&
        setAppValues({
          currentNav: currentNav,
        });
    }
  }, [router.isReady, router.pathname]);

  useEffect(() => {
    if (!appFocused && isFocused) {
      isMounted.current && setAppValues({ appFocused: true });
      return;
    }
    if (appFocused && !isFocused) {
      isMounted.current && setAppValues({ appFocused: false });
    }
  }, [isFocused]);

  /**
   * TEMP ugly workaround weird JSX element tsc error
   */
  const AnyComponent = Component as any;

  if (isLoading) {
    return <div />;
  }
  return (
    <>
      <SafeHydrate>
        <Navbar />
        <NonSSRPageContainer>
          <AnyComponent {...pageProps} err={err} />
        </NonSSRPageContainer>
      </SafeHydrate>
    </>
  );
}
