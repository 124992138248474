import CenteredLoader from "components/CenteredLoader";
import Text, { TextType } from "components/Text_DEPRECATED";
import { useCustomer } from "core/account/useCustomer";
import * as theme from "lib/styles/theme";
import { useRouter } from "next/router";
import MobileNavbarAccountMenu from "core/navbar/mobile/MobileNavbarAccountMenu";
import * as gtag from "lib/analytics/gtag";
import { AnalyticsEvents } from "lib/analytics/constants";
import { track } from "lib/analytics/analytics";
import { useCredentialContext } from "core/credentials/useCredentialContext";
import { useAppValues, initialValues } from "lib/context/AppContext";
import { useEffect, useRef, useState } from "react";
import { useWebRedirectKey } from "core/account/useWebRedirectKey";
import ProfilePhotoView, { ImageSize } from "components/ProfilePhotoView";
import styled from "styled-components";
import {
  BackgroundColorPalette,
  PrimaryColorPalette,
  TextAndShapesPalette,
} from "lib/styles/colors";
import { Z_INDEX } from "lib/types/common";
import { clearDataForLogout } from "lib/logout";
// export const BASE_DASH_URL = `https://dashboard.10ure.com`;
export const BASE_CREW_URL = `https://crew.10ure.com`;
// export const BASE_WEB_URL = `https://stg.10ure.com`;

// export const BASE_CREW_URL = `https://crew-stg.10ure.com`;
// export const BASE_WEB_URL = `https://stg-web.10ure.com`;

// export const BASE_CREW_URL = `http://localhost:3000`;
// export const BASE_WEB_URL = `http://localhost:3002`;

const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
`;

const NavMenuDropdown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  top: 100px;
  right: 40px;
  min-width: 162px;
  background-color: ${PrimaryColorPalette.MAIN_6};
  border: 1px solid ${TextAndShapesPalette.DISABLED};
  border-radius: 8px;
  z-index: ${Z_INDEX.VERY_TOP};
`;

interface MenuStyleProps {
  isLast?: boolean;
}

const IconWrapper = styled.div`
  justify-content: flex-end;
  /* padding-right: 40px; */
  margin-top: 8px;
  cursor: pointer;
`;

const MenuItem = styled.div<MenuStyleProps>`
  min-width: 266px;
  padding: 8px;
  ${({ isLast }) =>
    !isLast &&
    `
  border-bottom: 1px solid ${TextAndShapesPalette.DISABLED};
`}
  cursor: pointer;
  &:hover {
    background-color: ${BackgroundColorPalette.OFF_WHITE_BACKGROUND};
  }
`;

interface OwnProps {
  mobile?: boolean;
  userRefId?: string | null;
}

export default function NavbarAccountMenu(props: OwnProps): JSX.Element {
  const router = useRouter();
  const [dropdownEnabled, setDropdownEnabled] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);
  const { userRefId } = props;
  const { setAppValues } = useAppValues();
  const { customer, usrIsLoading, usrError } = useCustomer({
    userRefId: userRefId,
  });
  const isLoggedIn = customer && customer.refId ? true : false;
  const { authResp } = useWebRedirectKey({
    destination: "dashboard",
    isLoggedIn,
    setAppValues,
  });
  useCredentialContext({
    refreshContext: false,
    setAppValues,
    userRefId: userRefId ?? null,
  });

  useEffect(() => {
    if (userRefId && usrError) {
      console.warn("note usrError - clearing data: ", usrError);
      clearDataForLogout();
    }
  }, [userRefId, usrError]);

  const handleSupport = () => {
    gtag.event({
      action: "nav_menu_clicked_support",
      category: "engagement",
    });
    track(AnalyticsEvents.NAVBAR_CLICK_SUPPORT);
    router.push("/support");
  };

  const handleProfile = () => {
    if (authResp !== null && authResp.token) {
      gtag.event({
        action: "nav_menu_clicked_account",
        category: "engagement",
      });
      track(AnalyticsEvents.NAVBAR_CLICK_ACCOUNT);
      router.push(
        `${BASE_CREW_URL}/crew/${customer.refId}/redirect?code=${authResp.token}`
      );
    } else {
      throw Error("Expected authResp.token to be defined");
    }
  };

  // const handleAccount = () => {
  //   gtag.event({
  //     action: "nav_menu_clicked_account",
  //     category: "engagement",
  //   });
  //   track(AnalyticsEvents.NAVBAR_CLICK_ACCOUNT);
  //   router.push("/account");
  // };

  const handleLogout = () => {
    gtag.event({
      action: "nav_menu_sign_out",
      category: "engagement",
    });
    track(AnalyticsEvents.NAVBAR_CLICK_SIGN_OUT);
    clearDataForLogout();
    // destroyCookie(null, "userToken");
    // destroyCookie(null, "userRefId");

    // destroyCookie(null, "token");
    // destroyCookie(null, "xToken");
    // const storage = process.browser ? localStorage : undefined;
    // storage && storage.clear();
    setAppValues({ ...initialValues });
    if (typeof window !== "undefined") {
      window.location.href = "/";
    } else {
      // I think this is unreachable
      router.push("/");
    }
  };

  if (usrIsLoading) {
    return <CenteredLoader noMargin color={theme.colors.brand.Branding} />;
  }

  // Not actually the logic for this
  const isUser = Boolean(userRefId);

  const handleBtnClick = () => {
    if (dropdownEnabled) {
      setDropdownEnabled(false);
      return;
    }

    setDropdownEnabled(true);
  };

  if (props.mobile) {
    return (
      <MobileNavbarAccountMenu
        isUser={isUser}
        handleAccount={handleProfile}
        handleSupport={handleSupport}
        handleLogout={handleLogout}
      />
    );
  }

  return (
    <Container ref={divRef}>
      <IconWrapper onClick={handleBtnClick}>
        {/* ACTUAL PROFILE PIC */}
        <ProfilePhotoView size={ImageSize.TINY} imageUrl={customer?.imageUrl} />
      </IconWrapper>
      {dropdownEnabled && isUser && (
        <NavMenuDropdown>
          <MenuItem onClick={handleProfile}>
            <Text type={TextType.PARAGRAPH}>Account</Text>
          </MenuItem>
          {/* <MenuItem minWidth="266px" onClick={handleAccount}>
            <Text type={TextType.PARAGRAPH}>Account</Text>
          </MenuItem> */}
          <MenuItem onClick={handleSupport}>
            <Text type={TextType.PARAGRAPH}>Customer Support</Text>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <Text type={TextType.PARAGRAPH}>Sign Out</Text>
          </MenuItem>
        </NavMenuDropdown>
      )}
      {dropdownEnabled && !isUser && (
        <NavMenuDropdown>
          <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
        </NavMenuDropdown>
      )}
    </Container>
  );
}
