import React from "react";

const BASE_WIDTH = 24;
const BASE_HEIGHT = 24;

export default function PhoneIcon(props: { height?: number; width?: number }) {
  const width = props.width || BASE_WIDTH;
  const height = props.height || BASE_HEIGHT;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25 22H16.75C17.241 22 17.6406 21.6004 17.6406 21.1094V3.89062C17.6406 3.39959 17.241 3 16.75 3H7.25C6.75897 3 6.35938 3.39959 6.35938 3.89062V21.1094C6.35938 21.6004 6.75897 22 7.25 22ZM6.95312 3.89062C6.95312 3.81189 6.9844 3.73638 7.04008 3.6807C7.09575 3.62503 7.17126 3.59375 7.25 3.59375H16.75C16.8287 3.59375 16.9042 3.62503 16.9599 3.6807C17.0156 3.73638 17.0469 3.81189 17.0469 3.89062V21.1094C17.0469 21.1881 17.0156 21.2636 16.9599 21.3193C16.9042 21.375 16.8287 21.4062 16.75 21.4062H7.25C7.17126 21.4062 7.09575 21.375 7.04008 21.3193C6.9844 21.2636 6.95312 21.1881 6.95312 21.1094V3.89062Z"
        fill="#4D4D4D"
      />
      <path
        d="M7.84375 19.0312H16.1562C16.235 19.0312 16.3105 19 16.3662 18.9443C16.4218 18.8886 16.4531 18.8131 16.4531 18.7344V6.26562C16.4531 6.18689 16.4218 6.11138 16.3662 6.0557C16.3105 6.00003 16.235 5.96875 16.1562 5.96875H7.84375C7.76501 5.96875 7.6895 6.00003 7.63383 6.0557C7.57815 6.11138 7.54688 6.18689 7.54688 6.26562V18.7344C7.54688 18.8131 7.57815 18.8886 7.63383 18.9443C7.6895 19 7.76501 19.0312 7.84375 19.0312ZM8.14062 6.5625H15.8594V18.4375H8.14062V6.5625Z"
        fill="#4D4D4D"
      />
      <path
        d="M12.0297 20.8125C12.3576 20.8125 12.6234 20.5467 12.6234 20.2188C12.6234 19.8908 12.3576 19.625 12.0297 19.625C11.7017 19.625 11.4359 19.8908 11.4359 20.2188C11.4359 20.5467 11.7017 20.8125 12.0297 20.8125Z"
        fill="#4D4D4D"
      />
      <path
        d="M10.8125 5.375H13.1875C13.2662 5.375 13.3417 5.34372 13.3974 5.28805C13.4531 5.23237 13.4844 5.15686 13.4844 5.07812C13.4844 4.99939 13.4531 4.92388 13.3974 4.8682C13.3417 4.81253 13.2662 4.78125 13.1875 4.78125H10.8125C10.7338 4.78125 10.6583 4.81253 10.6026 4.8682C10.5469 4.92388 10.5156 4.99939 10.5156 5.07812C10.5156 5.15686 10.5469 5.23237 10.6026 5.28805C10.6583 5.34372 10.7338 5.375 10.8125 5.375Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}
