import { ChakraProvider } from "@chakra-ui/react";
import theme from "lib/styles/theme";

import { AppProps } from "next/app";
import Head from "next/head";
import { cache, SWRConfig } from "swr";
import { authFetcher } from "lib/http/api";
import { AppProvider } from "lib/context/AppContext";
import { FeedProvider } from "lib/context/FeedContext";
import { MediaContextProvider } from "lib/media";
import { ReactElement } from "react";
import { NextComponentType, NextPageContext } from "next";
import { SSRAppContainer } from "core/home/SSRAppContainer";
import NonSSRAppWrapper from "core/home/NonSSRAppWrapper";
import useIsTabletOrSmaller from "lib/hooks/useIsTabletOrSmaller";
import AppStoreRedirect from "components/AppStoreRedirect";
import { JobProvider } from "lib/context/JobContext";

// export function reportWebVitals(metric: NextWebVitalsMetric) {
//   console.log('reportWebVitals: ', metric)
// }

const NON_SSR_ROUTES = ["/v2/jobs", "/dashboard"];

const SIZE_GATE_ENABLED = false;
// @ts-ignore - err not defined
function MyApp({ Component, pageProps, err }: AppProps) {
  const baseUrl: string = pageProps.baseUrl ?? "";
  const isTooSmall = useIsTabletOrSmaller();

  if (SIZE_GATE_ENABLED && isTooSmall && typeof window !== "undefined") {
    return (
      <>
        <Head>
          <title>10ure Dashboard</title>
          <link rel="icon" href="/favicon.ico" />
          <meta
            name="google-site-verification"
            content="Wk1tOuSSO1GAOIRDFMXlJlCDY_b50_K999TyT4t5wSE"
          />
          <meta
            name="google-site-verification"
            content="0fpbvwI13lXuOXuF2_iIsUmKOivJKhmDp52b2NML_w8"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="apple-itunes-app" content="app-id=1530699354" />
          <meta
            name="google-play-app"
            content="app-id=com.tenure.tenuremobile"
          />
        </Head>
        <AppStoreRedirect />
      </>
    );
  }
  if (baseUrl.length && NON_SSR_ROUTES.includes(baseUrl)) {
    return <NonSSRApp Component={Component} pageProps={pageProps} err={err} />;
  }
  return (
    <SSRFresnelApp Component={Component} pageProps={pageProps} err={err} />
  );
}

interface InnerProps {
  Component: NextComponentType<NextPageContext, any, Record<string, unknown>>;
  pageProps: any;
  err: any;
}

function NonSSRApp({ Component, pageProps, err }: InnerProps): ReactElement {
  return (
    <SWRConfig value={{ fetcher: authFetcher, onError: () => cache.clear() }}>
      <ChakraProvider theme={theme}>
        <Head>
          <meta name="apple-itunes-app" content="app-id=1530699354" />
          <meta
            name="google-play-app"
            content="app-id=com.tenure.tenuremobile"
          />
          <meta
            name="google-site-verification"
            content="Wk1tOuSSO1GAOIRDFMXlJlCDY_b50_K999TyT4t5wSE"
          />
          <meta
            name="google-site-verification"
            content="S2unTnW8c25IXcYFIRGN-Rdi4Mgvv4yqipVJT7j-O5o"
          />
          <meta
            name="google-site-verification"
            content="0fpbvwI13lXuOXuF2_iIsUmKOivJKhmDp52b2NML_w8"
          />
          <link rel="icon" href="/favicon.ico" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          {/* <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="ADGbouwIGOP4cMRV4KkrlBMWkFouoS21";analytics.SNIPPET_VERSION="4.13.2";
            analytics.load("ADGbouwIGOP4cMRV4KkrlBMWkFouoS21");
            analytics.page();
            }}()`}}
          /> */}
        </Head>
        <AppProvider>
          <FeedProvider>
            <JobProvider>
              <NonSSRAppWrapper
                Component={Component}
                pageProps={pageProps}
                err={err}
              />
            </JobProvider>
          </FeedProvider>
        </AppProvider>
      </ChakraProvider>
    </SWRConfig>
  );
}

function SSRFresnelApp({
  Component,
  pageProps,
  err,
}: InnerProps): ReactElement {
  return (
    <SWRConfig value={{ fetcher: authFetcher, onError: () => cache.clear() }}>
      {/* @ts-ignore */}
      <MediaContextProvider>
        <ChakraProvider theme={theme}>
          <Head>
            <meta name="apple-itunes-app" content="app-id=1530699354" />
            <meta
              name="google-play-app"
              content="app-id=com.tenure.tenuremobile"
            />
            <meta
              name="google-site-verification"
              content="Wk1tOuSSO1GAOIRDFMXlJlCDY_b50_K999TyT4t5wSE"
            />
            <meta
              name="google-site-verification"
              content="0fpbvwI13lXuOXuF2_iIsUmKOivJKhmDp52b2NML_w8"
            />
            <meta
              name="google-site-verification"
              content="0fpbvwI13lXuOXuF2_iIsUmKOivJKhmDp52b2NML_w8"
            />
            <link rel="icon" href="/favicon.ico" />
            {/* <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="ADGbouwIGOP4cMRV4KkrlBMWkFouoS21";analytics.SNIPPET_VERSION="4.13.2";
            analytics.load("ADGbouwIGOP4cMRV4KkrlBMWkFouoS21");
            analytics.page();
            }}()`}}
          /> */}
          </Head>
          <AppProvider>
            <FeedProvider>
              <JobProvider>
                <SSRAppContainer
                  Component={Component}
                  pageProps={pageProps}
                  err={err}
                />
              </JobProvider>
            </FeedProvider>
          </AppProvider>
        </ChakraProvider>
      </MediaContextProvider>
    </SWRConfig>
  );
}

export default MyApp;
