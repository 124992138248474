import { Box, CloseButton, Flex, RenderProps } from "@chakra-ui/react";
import { AnalyticsEvents } from "lib/analytics/constants";
import * as theme from "lib/styles/theme";
import SeagullMobileIcon from "lib/svgs/seagull/SeagullMobile";
import { CookieKeys } from "lib/types/common";
import { setCookie } from "nookies";
import { ChangeEvent, useEffect, useState } from "react";
import Button, { ButtonType } from "./Button";
import Heading, { HeadingType } from "./Heading";
import Input from "./Input";
import Text, { TextType } from "./Text_DEPRECATED";
import { formatEmail } from "lib/formatEmail";
import { validateEmail } from "lib/validateEmail";
import { useEmailSubscription } from "core/email-subscribe/useEmailSubscription";
import { track } from "lib/analytics/analytics";

interface OwnProps {
  userRefId?: string | null;
}

export default function EmailSubscriptionPopUp(
  props: RenderProps & OwnProps
): JSX.Element {
  const { onClose } = props;
  const [email, setEmail] = useState<string>();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const { subscribe, isPending } = useEmailSubscription();

  // returns date today: MM/DD/YYYY
  const getFormattedDate = () => {
    const date = new Date();
    return date.toLocaleDateString();
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    error && setError(null);
    setEmail(event.target.value);
  };

  // Track shown on mount
  useEffect(() => {
    track(AnalyticsEvents.EMAIL_PROMPT_SHOWN);
    setCookie(null, CookieKeys.EMAIL_PROMPT_ATTEMPTED, getFormattedDate(), {});
  }, []);

  const handleClose = () => {
    if (!success) {
      track(AnalyticsEvents.EMAIL_SUBSCRIBE_CLOSE, {
        isUser: Boolean(props.userRefId),
        userRefId: props.userRefId,
      });
    }
    onClose();
  };

  const handleSubmit = async () => {
    setSuccess(false);
    const emailInvalid = validateEmail(email);
    if (!email || emailInvalid) {
      setError(emailInvalid);
      return;
    }
    const formattedEmail = formatEmail(email);
    const success = await subscribe(formattedEmail);
    if (!success) {
      setError("Error submitting email.");
      return;
    }
    // Handle call to mailchimp, etc.
    setCookie(null, CookieKeys.EMAIL_PROMPT_SUBSCRIBED, getFormattedDate(), {});
    track(AnalyticsEvents.EMAIL_SUBSCRIBE, {
      isUser: Boolean(props.userRefId),
      userRefId: props.userRefId,
    });
    setSuccess(true);
  };

  return (
    <Box
      width="520"
      height="420"
      borderRadius="4px"
      backgroundColor={theme.colors.primary[7]}
    >
      <Box
        width="100%"
        height="12px"
        bgGradient="linear(to-r, #0ED4FF, #2DFFF2)"
      />
      <Flex paddingBottom="1rem" flexDir="column" alignItems="center">
        <Flex
          width="100%"
          flexDir="row"
          alignItems="center"
          justifyContent="flex-end"
          height="40px"
        >
          <CloseButton onClick={handleClose} />
        </Flex>
        <SeagullMobileIcon />
        <Flex
          paddingX="1rem"
          flexDirection="column"
          width="100%"
          justifyContent="flex-start"
        >
          <Heading paddingTop="0.5rem" type={HeadingType.HEADING_SMALL}>
            Keep up with the latest Maritime trends
          </Heading>
          <Text textAlign="left" paddingTop="0.5rem" paddingBottom="1.5rem">
            Be the first to learn about the latest maritime jobs and news with
            10ure&apos;s weekly digest!
          </Text>
          {Boolean(!success) && (
            <>
              <Input
                marginBottom="16px"
                width={"100%"}
                placeholder="Your Email"
                onChange={handleEmailChange}
                value={email || ""}
              />
              {Boolean(error) && (
                <Text
                  color={theme.colors.brand.WarningText}
                  textAlign="center"
                  paddingTop="0.5rem"
                  paddingBottom="1.5rem"
                >
                  {error}
                </Text>
              )}
              {!error && (
                <Button
                  width={"100%"}
                  textType={TextType.BUTTON}
                  styleType={ButtonType.PRIMARY}
                  onClick={handleSubmit}
                  isLoading={isPending}
                  isDisabled={!email}
                >
                  Subscribe
                </Button>
              )}
            </>
          )}
          {success && (
            <Box width="100%" paddingY="2.5rem">
              <Text
                color={theme.colors.brand.SuccessText}
                textAlign="center"
                paddingTop="0.5rem"
                paddingBottom="1.5rem"
              >
                Thank you for subscribing!
              </Text>
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
