import React from "react";

const BASE_WIDTH = 40;
const BASE_HEIGHT = 40;

export default function NavBrandLogoSmall(props: {
  height?: number;
  width?: number;
}) {
  const width = props.width || BASE_WIDTH;
  const height = props.height || BASE_HEIGHT;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${BASE_WIDTH} ${BASE_HEIGHT}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.20867 27.3447V37.9104C6.20867 38.0876 6.20313 38.1928 6.19206 38.226C6.18098 38.2482 6.12561 38.2593 6.02593 38.2593H5.54416C5.46664 38.2593 5.4168 38.2426 5.39465 38.2094C5.38357 38.1651 5.37804 38.1097 5.37804 38.0433V27.6105L2.52065 29.8698C2.37668 29.9806 2.25485 29.9695 2.15517 29.8366L2.00566 29.6539C1.96136 29.5985 1.93921 29.5376 1.93921 29.4711C1.93921 29.4047 1.98905 29.3327 2.08872 29.2552L4.94611 26.9294C5.20083 26.73 5.45556 26.6304 5.71029 26.6304H5.74351C6.05362 26.6414 6.20867 26.8796 6.20867 27.3447Z"
        fill="#24D9FF"
      />
      <path
        d="M12.0864 26.6304H12.2858C13.194 26.6304 13.9581 26.8851 14.5784 27.3946C15.1986 27.904 15.5087 28.7014 15.5087 29.7868V34.9201C15.5087 36.0276 15.2096 36.8638 14.6116 37.4286C14.0135 37.9824 13.2216 38.2703 12.236 38.2925H12.1363C11.1395 38.2703 10.3421 37.9824 9.74406 37.4286C9.146 36.8638 8.84697 36.0276 8.84697 34.9201V29.7868C8.84697 29.0558 9.00756 28.4467 9.32874 27.9594C9.90465 27.0734 10.8239 26.6304 12.0864 26.6304ZM12.319 27.4278H12.0532C11.3998 27.4278 10.8405 27.6105 10.3753 27.976C9.91018 28.3304 9.67761 28.9506 9.67761 29.8366V34.9035C9.67761 36.6312 10.4806 37.4951 12.0864 37.4951H12.2858C13.8917 37.4951 14.6946 36.6312 14.6946 34.9035V29.8366C14.6946 28.9506 14.4621 28.3304 13.9969 27.976C13.5318 27.6105 12.9725 27.4278 12.319 27.4278Z"
        fill="#24D9FF"
      />
      <path
        d="M18.191 29.9197H18.3239C18.5454 29.9197 18.6561 30.0304 18.6561 30.2519V35.5015C18.6561 36.155 18.8167 36.6478 19.1379 36.9801C19.4701 37.3123 19.9353 37.4785 20.5333 37.4785C21.1314 37.4785 21.6464 37.2403 22.0783 36.7641C22.178 36.6423 22.2832 36.5814 22.394 36.5814C22.6044 36.5814 22.7096 36.7032 22.7096 36.9469C22.7096 37.0465 22.6819 37.1351 22.6265 37.2127C22.405 37.5228 22.0949 37.7775 21.6962 37.9768C21.2975 38.1651 20.8767 38.2593 20.4337 38.2593C19.6695 38.2593 19.0493 38.0211 18.5731 37.5449C18.1079 37.0576 17.8753 36.382 17.8753 35.5182V30.2519C17.8753 30.0304 17.9805 29.9197 18.191 29.9197ZM23.1083 29.9529H23.2744C23.4849 29.9529 23.5901 30.0637 23.5901 30.2852V36.3156C23.5901 36.7475 23.6233 37.0465 23.6898 37.2127C23.7673 37.3677 23.9113 37.4729 24.1217 37.5283L24.3044 37.5781C24.4706 37.6114 24.5536 37.6833 24.5536 37.7941V37.9768C24.5536 38.1651 24.4429 38.2593 24.2214 38.2593C23.6787 38.2593 23.3021 38.0987 23.0917 37.7775C22.8924 37.4563 22.7927 36.9635 22.7927 36.299V30.2852C22.7927 30.0637 22.8979 29.9529 23.1083 29.9529Z"
        fill="#24D9FF"
      />
      <path
        d="M28.9568 29.9197C29.1672 29.9197 29.2724 30.0304 29.2724 30.2519V30.3682C29.2724 30.4568 29.2392 30.5344 29.1727 30.6008C29.1174 30.6673 29.0454 30.7005 28.9568 30.7005C28.3255 30.7005 27.8216 30.8611 27.445 31.1822C27.0685 31.5034 26.8802 31.9963 26.8802 32.6608V37.927C26.8802 38.0267 26.847 38.1097 26.7805 38.1762C26.714 38.2316 26.6365 38.2593 26.5479 38.2593H26.4316C26.343 38.2593 26.2655 38.2316 26.1991 38.1762C26.1326 38.1097 26.0994 38.0267 26.0994 37.927V32.6608C26.0994 31.7526 26.3541 31.0715 26.8636 30.6174C27.373 30.1523 28.0708 29.9197 28.9568 29.9197Z"
        fill="#24D9FF"
      />
      <path
        d="M30.9672 32.6608C30.9672 31.7858 31.233 31.1103 31.7646 30.634C32.2962 30.1578 32.9884 29.9197 33.8412 29.9197C34.6939 29.9197 35.3806 30.1633 35.9011 30.6506C36.4217 31.1269 36.6819 31.7969 36.6819 32.6608V33.6575C36.6819 34.1338 36.4549 34.3719 36.0008 34.3719H32.4125C32.3128 34.3719 32.2297 34.3387 32.1633 34.2722C32.1079 34.2058 32.0802 34.1282 32.0802 34.0396V33.9067C32.0802 33.8181 32.1079 33.7406 32.1633 33.6742C32.2297 33.6077 32.3128 33.5745 32.4125 33.5745H35.9011V32.6774C35.9011 32.0461 35.7073 31.5643 35.3197 31.2321C34.9321 30.8888 34.4392 30.7171 33.8412 30.7171C33.2431 30.7171 32.7447 30.8888 32.346 31.2321C31.9584 31.5643 31.7646 32.0461 31.7646 32.6774V35.5348C31.7646 36.1661 31.9584 36.6534 32.346 36.9967C32.7447 37.3289 33.2376 37.4951 33.8245 37.4951C34.4226 37.4951 34.9154 37.3345 35.3031 37.0133C35.7018 36.681 35.9011 36.2104 35.9011 35.6012C35.9011 35.5126 35.9288 35.4351 35.9842 35.3686C36.0506 35.3022 36.1282 35.269 36.2168 35.269H36.3497C36.4493 35.269 36.5269 35.3022 36.5822 35.3686C36.6487 35.4351 36.6819 35.5126 36.6819 35.6012C36.6708 36.454 36.405 37.1185 35.8845 37.5947C35.364 38.0599 34.6829 38.2925 33.8412 38.2925C32.9994 38.2925 32.3072 38.0544 31.7646 37.5781C31.233 37.0908 30.9672 36.4152 30.9672 35.5514V32.6608Z"
        fill="#24D9FF"
      />
      <path
        d="M13.8874 1.19351C12.6792 -0.0146999 10.7203 -0.0147003 9.51212 1.19351C8.3039 2.40173 8.3039 4.36063 9.51212 5.56884L15.2055 11.2622L9.51212 16.9555C8.30391 18.1638 8.30391 20.1227 9.51212 21.3309C10.7203 22.5391 12.6792 22.5391 13.8875 21.3309L19.5808 15.6375L25.2741 21.3309C26.4824 22.5391 28.4413 22.5391 29.6495 21.3309C30.8577 20.1227 30.8577 18.1638 29.6495 16.9555L23.9561 11.2622L29.6495 5.56885C30.8577 4.36064 30.8577 2.40173 29.6495 1.19352C28.4413 -0.0146958 26.4824 -0.0146961 25.2741 1.19352L19.5808 6.88687L13.8874 1.19351Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M25.2585 12.564L29.65 16.9555C30.8582 18.1637 30.8582 20.1226 29.65 21.3308C28.4417 22.539 26.4828 22.539 25.2746 21.3308L19.5813 15.6374L13.8879 21.3308C12.6797 22.539 10.7208 22.539 9.51261 21.3308C8.30439 20.1226 8.30439 18.1637 9.51261 16.9555L13.5965 12.8716C14.2385 12.4969 15.1214 12.1533 16.2007 12.1533C17.5909 12.1533 18.411 12.6419 19.0905 13.0467C19.5709 13.3329 19.981 13.5772 20.4725 13.5772C20.964 13.5772 21.2519 13.3737 21.5892 13.1351C22.0662 12.7978 22.6421 12.3906 24.0323 12.3906C24.5223 12.3906 24.9174 12.4539 25.2585 12.564Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M27.8183 15.1245L29.6492 16.9554C30.8574 18.1636 30.8574 20.1225 29.6492 21.3308C28.441 22.539 26.4821 22.539 25.2739 21.3308L19.5806 15.6374L13.8872 21.3308C12.679 22.539 10.7201 22.539 9.51187 21.3308C8.30366 20.1225 8.30366 18.1636 9.51187 16.9554L12.7589 13.7084C13.2107 13.4846 13.7271 13.3399 14.3013 13.3399C15.6915 13.3399 16.2674 13.6656 16.7444 13.9355C17.0817 14.1263 17.3697 14.2892 17.8612 14.2892C18.3527 14.2892 18.7627 14.0042 19.2432 13.6702C19.9226 13.198 20.7427 12.6279 22.1329 12.6279C24.5061 12.6279 25.6928 14.2892 25.6928 14.2892C26.092 14.6885 26.6593 14.9198 27.8183 15.1245Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M28.1628 15.4693L29.6492 16.9557C30.8574 18.1639 30.8574 20.1228 29.6492 21.331C28.441 22.5392 26.4821 22.5392 25.2739 21.331L19.5806 15.6377L13.8872 21.331C12.679 22.5392 10.7201 22.5392 9.51187 21.331C8.30366 20.1228 8.30366 18.1639 9.51187 16.9557L11.0645 15.403C11.7973 15.3029 12.6246 15.3274 13.352 15.5168C15.4837 16.0717 16.2611 15.8928 17.3865 15.5167C17.7889 15.3823 18.0968 15.1709 18.4337 14.9396C19.0392 14.524 19.7384 14.044 21.2481 13.8303C23.5978 13.4976 25.0056 14.9761 25.0056 14.9761C25.3744 15.3233 26.4812 15.9176 27.9584 15.5167C28.0257 15.4985 28.0938 15.4827 28.1628 15.4693Z"
        fill="url(#paint3_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="19.1216"
          y1="8.81773"
          x2="14.8119"
          y2="13.1751"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0ED4FF" />
          <stop offset="1" stopColor="#2DFFF2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="15.3337"
          y1="12.7228"
          x2="28.5391"
          y2="14.7312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#24D9FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="14.6944"
          y1="14.1204"
          x2="30.1133"
          y2="17.7507"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3CACEB" stopOpacity="0.8" />
          <stop offset="1" stopColor="#17E0FB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="11.5478"
          y1="16.5797"
          x2="33.9077"
          y2="19.9103"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0ED4FF" />
          <stop offset="0.177083" stopColor="#3CACEB" />
        </linearGradient>
      </defs>
    </svg>
  );
}
